import useaxios from "../../utils/customAxios";
import { GET_MESSAGES, GET_OTHER_USER, GET_UNSEEN_CONVERSATIONS, GET_UNSEEN_MESSAGES, REMOVE_SEEN_MESSAGES, SET_MESSAGE, SET_NEW_MESSAGE, SET_UNSEEN_MESSAGE } from "../types/messageTypes"
const api=useaxios()
// get messages of a conversations
export const getMessages=(conversationId)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/messages/${conversationId}`)
        dispatch({
            type:GET_MESSAGES,
            payload:res.data
        })
    } catch (error) {
        console.log({msg:'could not get messages', error})
    }
}
// set new message
export const setMessage=(message)=>async(dispatch)=>{
    
    try {
        const res=await api.post('/api/messages', message)
        dispatch({
            type:SET_NEW_MESSAGE,
            payload:message
        })
    } catch (error) {
        console.log({msg:'could not set message', error})
    }
}
// set arrival message
export const getArrivalMessage=(message)=>{
    return{
        type:SET_MESSAGE,
        payload:message
    }
}
//get sender of a message
export const getOtherUser=(id)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/profile/${id}`)
        dispatch({
            type:GET_OTHER_USER,
            payload:res.data.user
        })
    } catch (error) {
        console.log({msg:"could not get other user", error})
    }
}
// get unseen messages
export const getUnseenMessages=()=>async(dispatch)=>{
    
    try {
        const res=await api.get('/api/messages/unseenMessages')
        dispatch({
            type:GET_UNSEEN_MESSAGES,
            payload:res.data.unseenMessages
        })
    } catch (error) {
        console.log({msg:"could not unseen messages", error})
    }
}
// Set unseen message
export const setUnseenMessage=(unseenMessage)=>{
    return {
        type:SET_UNSEEN_MESSAGE,
        payload:unseenMessage
    }
}
// get unseen conversations
export const getUnseenConversations=()=>{
    return {
        type:GET_UNSEEN_CONVERSATIONS,
    }
}
// remove seen messages from unseen messages
export const removeSeenMessages=(conversationId)=>{
    return {
        type:REMOVE_SEEN_MESSAGES,
        payload:conversationId,
    }
}