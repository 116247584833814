import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Load from "../Components/Load";

const AdminRoute = ({ children }) => {
  const { auth, user } = useSelector((state) => state.authReducer);
  const loading = useSelector((state) => state.authReducer.loading);
  const token = localStorage.getItem("authTokens");
  return (
    <div>
      {loading ? (
        <Load />
      ) : auth && token && user.role === "admin" ? (
        children
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default AdminRoute;
