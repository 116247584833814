import React from "react";
import "./message.css";
import { format } from "timeago.js";
import { useSelector } from "react-redux";

function Message({ message, own }) {
  const { user } = useSelector((state) => state.authReducer);
  const { otherUser } = useSelector((state) => state.messageReducer);
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        <img
          className="messageImg"
          src={
            own
              ? `uploads/${user && user.imageUrl}`
              : `uploads/${otherUser && otherUser.imageUrl}`
          }
          alt=""
        ></img>
        <p className="messageText">{message.text}</p>
      </div>
      <div className="messageBottom">{format(message.createdAt)}</div>
    </div>
  );
}

export default Message;
