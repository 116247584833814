import { combineReducers } from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import commissionReducer from "./commissionReducer";
import announcementReducer from "./announcementReducer";
import conversationReducer from "./conversationReducer";
import messageReducer from "./messageReducer";
import notificationReducer from "./notificationReducer";
import blogReducer from "./blogReducer";
import sliderReducer from "./sliderReducer";

const rootReducer = combineReducers({
  authReducer,
  alertReducer,
  commissionReducer,
  announcementReducer,
  conversationReducer,
  messageReducer,
  notificationReducer,
  blogReducer,
  sliderReducer,
});
export default rootReducer;
