import { CURRENT, FAIL, LOGIN, LOGOUT, REGISTER } from "../types/authTypes";
import axios from "axios";
import { setAlert } from "./alertActions";
import useaxios from "../../utils/customAxios";
const api=useaxios()
// sign up particular
export const registerParticular =
  (data, acceptedTerms, Photo, PhotoPassport, navigate) => async (dispatch) => {
    const info = new FormData();
    info.append("name", data.name);
    info.append("phone", data.phone);
    info.append("birth", data.birth);
    info.append("photo", Photo);
    info.append("passport", data.passport);
    info.append("passportPhoto", PhotoPassport);
    info.append("RIB", data.rib);
    info.append("email", data.email);
    info.append("password", data.password);
    info.append("acceptedTerms", acceptedTerms);
    try {
      const res = await axios.post("https://api.colispo.com/api/auth/signupParticular", info);
      dispatch({
        type: REGISTER,
        payload: res.data,
      });
      navigate("/profile");
    } catch (error) {
      error.response.data.errors.forEach((err) =>
        dispatch(setAlert(err.msg, "danger"))
      );
      dispatch({
        type: FAIL,
      });
    }
  };
// sign up professional
export const registerProfessional =
  (
    data,
    acceptedTerms,
    Photo,
    PatentPhoto,
    commercialRegisterPhoto,
    navigate
  ) =>
  async (dispatch) => {
    const info = new FormData();
    info.append("name", data.name);
    info.append("phone", data.phone);
    info.append("birth", data.birth);
    info.append("photo", Photo);
    info.append("patentPhoto", PatentPhoto);
    info.append("commercialRegister", commercialRegisterPhoto);
    info.append("RIB", data.RIB);
    info.append("email", data.email);
    info.append("password", data.password);
    info.append("acceptedTerms", acceptedTerms);
    try {
      const res = await axios.post("https://api.colispo.com/api/auth/signupProfessional", info);
      dispatch({
        type: REGISTER,
        payload: res.data,
      });
      navigate("/profile");
    } catch (error) {
      error.response.data.errors.forEach((err) =>
        dispatch(setAlert(err.msg, "danger"))
      );
      dispatch({
        type: FAIL,
      });
    }
  };
//sign in
export const login = (data, navigate) => async (dispatch) => {
  try {
    console.log(data)
    const res = await axios.post("https://api.colispo.com/api/auth/signin", data);
    
    dispatch({
      type: LOGIN,
      payload: res.data,
    });
    navigate("/profile");
  } catch (error) {
    error.response.data.errors.forEach((err) =>
      dispatch(setAlert(err.msg, "danger"))
    );
    dispatch({
      type: FAIL,
    });
  }
};
// get current
export const getCurrentUser = () => async (dispatch) => {

  try {
    const res = await api.get("/api/auth/current");
    dispatch({
      type: CURRENT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FAIL,
    });
  }
};
// logout
export const logout = () => {
  return {
    type: LOGOUT,
  };
};
