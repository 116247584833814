import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Announcement from "../Components/Announcement";
import Load from "../Components/Load";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  firstAnnouncements,
  getAnnouncements,
  setCurrentPage,
  setLoading,
  setSearchTrue,
} from "../redux/actions/announcementActions";
import { useNavigate } from "react-router-dom";
import "./Announcements.css";
import { useTranslation } from "react-i18next";
function Announcements() {
  //handle set depart city
  const [depart, setDepart] = useState("");
  const handleDepart = (e) => {
    setDepart(e.target.value);
  };
  // handle set arrival city
  const [arrival, setArrival] = useState("");
  const handleArrival = (e) => {
    setArrival(e.target.value);
  };
  // handle set Date
  const [date, setDate] = useState("");
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  // handle set type of service
  const [typeService, setTypeService] = useState("");
  const handleTypeService = (e) => {
    setTypeService(e.target.value);
  };
  // handle set dimension
  const [dimension, setDimension] = useState("");
  const handleDimension = (e) => {
    setDimension(e.target.value);
  };
  // handle set dimension
  const [weight, setWeight] = useState("");
  const handleWeight = (e) => {
    setWeight(e.target.value);
  };
  // handle set category
  const [category, setCategory] = useState("");
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };
  // handle render first page
  const dispatch = useDispatch();
  const {
    firstPage,
    announcements,
    currentPage,
    total,
    loading,
    search,
    depart1,
    arrival1,
  } = useSelector((state) => state.announcementReducer);
  useEffect(() => {
    depart1 && setDepart(depart1);
    arrival1 && setArrival(arrival1);

    dispatch(getAnnouncements(firstPage, depart1, arrival1));
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   dispatch(firstAnnouncements(firstPage));
  // }, []);

  // handle Page Click
  const handlePageClick = (data) => {
    dispatch(setLoading());
    if (search) {
      dispatch(
        getAnnouncements(
          data.selected + 1,
          depart,
          arrival,
          date,
          typeService,
          dimension,
          weight,
          category
        )
      );
      dispatch(setCurrentPage(data.selected));
    }
    if (!search) {
      dispatch(firstAnnouncements(data.selected + 1));
      dispatch(setCurrentPage(data.selected));
    }
    window.scrollTo(0, 0);
  };
  // handle click search
  const handleClickSearch = () => {
    dispatch(setLoading());
    dispatch(setCurrentPage(0));
    dispatch(setSearchTrue());
    dispatch(
      getAnnouncements(
        firstPage,
        depart,
        arrival,
        date,
        typeService,
        dimension,
        weight,
        category
      )
    );
  };
  const navigate = useNavigate();
  const [isOpenmenu, setIsOpenmenu] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div>
        <div className="Container">
          <div className="Search">
            <div className="Container1">
              <input
                className="element1"
                value={depart}
                placeholder={t("EnvoyerColis.Ville de départ")}
                onChange={handleDepart}
              />
              <input
                className="element2"
                value={arrival}
                placeholder={t("EnvoyerColis.Ville d'arrivée")}
                onChange={handleArrival}
              />
              <input
                className="element3"
                type="date"
                placeholder={t("EnvoyerColis.Date")}
                onChange={handleDate}
              />
              <button className="search" onClick={handleClickSearch}>
                {t("VoirAnnonces.Trouver des expéditeurs")}
              </button>
              <button
                className="publish"
                onClick={() => navigate("/SendColis")}
              >
                {t("VoirAnnonces.Publier une annonce")}
              </button>
            </div>
            <div className="mobilemenu-content">
              <div
                role="button"
                className="mobilemeu"
                onClick={() => setIsOpenmenu(!isOpenmenu)}
              >
                <span>{t("VoirAnnonces.Affiner votre recherche")}</span>
                <i
                  className={
                    isOpenmenu ? "fa fa-close" : "fa fa-bars pull-right"
                  }
                ></i>
              </div>
              <div
                className={
                  isOpenmenu
                    ? "navigation-mobile openmenu"
                    : "navigation-mobile"
                }
              >
                <ul class="mobile-area-main">
                  <li>
                    <select
                      className="form_search"
                      onChange={handleTypeService}
                    >
                      <option value="" className="item">
                        {t("EnvoyerColis.Type de service")}
                      </option>
                      <option value="Je veux envoyer un colis" className="item">
                        {t("VoirAnnonces.Achat")}
                      </option>
                      <option
                        value="Je veux transporter un colis"
                        className="item"
                      >
                        {t("VoirAnnonces.Transport")}
                      </option>
                      <option
                        value="Je veux acheter et recevoir un colis"
                        className="item"
                      >
                        {t("VoirAnnonces.Achat et transport")}
                      </option>
                    </select>
                  </li>
                  <li>
                    <select className="form_search1" onChange={handleDimension}>
                      <option value="" className="item">
                        {t("EnvoyerColis.Dimension")}
                      </option>
                      <option value="Petit" className="item">
                        {t("EnvoyerColis.Petit")}
                      </option>
                      <option value="Moyen" className="item">
                        {t("EnvoyerColis.Moyen")}
                      </option>
                      <option value="Grand" className="item">
                        {t("EnvoyerColis.Grand")}
                      </option>
                      <option value="Très Grand" className="item">
                        {t("EnvoyerColis.Très grand")}
                      </option>
                    </select>
                  </li>
                  <li>
                    <select className="form_search2" onChange={handleWeight}>
                      <option value="" className="item">
                        {t("VoirAnnonces.Poids")}
                      </option>
                      <option value="0g - 500g" className="item">
                        0g - 500g
                      </option>
                      <option value="500g - 1Kg" className="item">
                        500g - 1Kg
                      </option>
                      <option value="1Kg - 2Kg" className="item">
                        1Kg - 2Kg
                      </option>
                      <option value="Plus de 2Kg" className="item">
                        {t("EnvoyerColis.Plus de 2Kg")}
                      </option>
                    </select>
                  </li>
                  <li>
                    <select className="form_search3" onChange={handleCategory}>
                      <option value="" className="item">
                        {t("VoirAnnonces.Ctégorie")}
                      </option>
                      <option value="Particular" className="item">
                        {t("VoirAnnonces.Particulier")}
                      </option>
                      <option value="Professional" className="item">
                        {t("VoirAnnonces.Professionnel")}
                      </option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Container2">
              <div className="propositions-research">
                <h3>{t("VoirAnnonces.Affiner votre recherche")}</h3>
              </div>
              <select className="form_search" onChange={handleTypeService}>
                <option value="" className="item">
                  {t("EnvoyerColis.Type de service")}
                </option>
                <option value="Je veux envoyer un colis" className="item">
                  {t("VoirAnnonces.Achat")}
                </option>
                <option value="Je veux transporter un colis" className="item">
                  {t("VoirAnnonces.Transport")}
                </option>
                <option
                  value="Je veux acheter et recevoir un colis"
                  className="item"
                >
                  {t("VoirAnnonces.Achat et transport")}
                </option>
              </select>
              <select className="form_search1" onChange={handleDimension}>
                <option value="" className="item">
                  {t("EnvoyerColis.Dimension")}
                </option>
                <option value="Petit" className="item">
                  {t("EnvoyerColis.Petit")}
                </option>
                <option value="Moyen" className="item">
                  {t("EnvoyerColis.Moyen")}
                </option>
                <option value="Grand" className="item">
                  {t("EnvoyerColis.Grand")}
                </option>
                <option value="Très Grand" className="item">
                  {t("EnvoyerColis.Très grand")}
                </option>
              </select>
              <select className="form_search2" onChange={handleWeight}>
                <option value="" className="item">
                  {t("VoirAnnonces.Poids")}
                </option>
                <option value="0g - 500g" className="item">
                  0g - 500g
                </option>
                <option value="500g - 1Kg" className="item">
                  500g - 1Kg
                </option>
                <option value="1Kg - 2Kg" className="item">
                  1Kg - 2Kg
                </option>
                <option value="Plus de 2Kg" className="item">
                  {t("EnvoyerColis.Plus de 2Kg")}
                </option>
              </select>
              <select className="form_search3" onChange={handleCategory}>
                <option value="" className="item">
                  {t("VoirAnnonces.Ctégorie")}
                </option>
                <option value="Particular" className="item">
                  {t("VoirAnnonces.Particulier")}
                </option>
                <option value="Professional" className="item">
                  {t("VoirAnnonces.Professionnel")}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="propositions">
          <div className="propositions-title">
            <h1>{t("VoirAnnonces.Dernières propositions")}</h1>
          </div>
          <div className="propositions-announcements">
            <div>
              {loading ? (
                <Load />
              ) : (
                announcements &&
                announcements.map((announcement) => (
                  <div className="announcement" key={announcement._id}>
                    {" "}
                    <Announcement announcement={announcement} />{" "}
                  </div>
                ))
              )}
            </div>
            <div>
              <ReactPaginate
                previousAriaLabel={"<<"}
                nextLabel={">>"}
                previousLabel={"<<"}
                breakLabel={"..."}
                pageCount={total}
                marginPagesDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Announcements;
