import { GET_MESSAGES, GET_OTHER_USER, GET_UNSEEN_CONVERSATIONS, GET_UNSEEN_MESSAGES, REMOVE_SEEN_MESSAGES, SET_MESSAGE, SET_NEW_MESSAGE, SET_UNSEEN_MESSAGE } from "../types/messageTypes";


const initialState={
    messages:[],
    otherUser:null,
    unseenMessages:null,
    unseenConversations:[],
    unseenMessagesLength:null,
}

const messageReducer=(state=initialState, {type,payload})=>{
    switch (type) {
        case GET_MESSAGES:
            return {...state, messages:payload}
        case SET_NEW_MESSAGE:
            return {...state, messages:[...state.messages, payload]}
        case SET_MESSAGE:
            return {...state, messages:[...state.messages, payload]}
        case GET_OTHER_USER:
            return {...state, otherUser:payload}
        case GET_UNSEEN_MESSAGES:
            return {...state, unseenMessages:payload}
        case SET_UNSEEN_MESSAGE:
            let {conversationId,sender,receiver,senderName,senderImageUrl,text,model_type,seen,createdAt}=payload;
            let unseenMessage={conversationId,receiver, sender:{id:sender,name:senderName,imageUrl:senderImageUrl}, text, model_type, seen, createdAt}
            return {...state, unseenMessages:[...state.unseenMessages, unseenMessage]}
        case GET_UNSEEN_CONVERSATIONS:
            state.unseenConversations=[];
            for (let i=state.unseenMessages?.length-1;i>=0;i--){
                !(state.unseenConversations.includes(state.unseenMessages[i].conversationId)) && state.unseenConversations.push(state.unseenMessages[i].conversationId)
            }
            return {...state, unseenConversations:state.unseenConversations, unseenMessagesLength:state.unseenConversations.length}
        case REMOVE_SEEN_MESSAGES:
            return {...state, unseenMessages:state.unseenMessages.filter(m => m.conversationId !== payload)}
        default:
            return state;
    }
}

export default messageReducer