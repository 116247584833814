import { DELETE_BLOG, GET_BLOGS } from "../types/blogTypes";

const initialState = {
  blogs: null,
};

const blogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BLOGS:
      return { ...state, blogs: payload };
    case DELETE_BLOG:
      return {
        ...state,
        blogs: state.blogs.filter((blog) => blog._id !== payload),
      };
    default:
      return state;
  }
};

export default blogReducer;
