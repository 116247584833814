import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "../../redux/actions/profileActions";
import "./conversation.css";

function Conversation({ conversation, currentUser }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const friendId = conversation.members.find((m) => m !== currentUser._id);
    dispatch(getUser(friendId, setUser));
  }, [currentUser, conversation]);
  return (
    <div className="conversation">
      <img
        className="conversationImg"
        src={`uploads/${user && user.imageUrl}`}
        alt=""
      ></img>
      <span className="conversationName">{user && user.name}</span>
    </div>
  );
}

export default Conversation;
