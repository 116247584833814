import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSeenNotifications } from "../../redux/actions/notificationActions";
import {
  editCommercialRegister,
  editEmailParticular,
  editEmailProfessional,
  editPassportParticular,
  editPasswordParticular,
  editPasswordProfessional,
  editPatentPhoto,
  editPhoneParticular,
  editPhoneProfessional,
  editPhotoParticular,
  editPhotoProfessional,
} from "../../redux/actions/profileActions";
import { format } from "timeago.js";
import "./Dashboard.css";
import {
  setCurrentConversation,
  setCurrentUnseenChat,
} from "../../redux/actions/conversationAction";
import {
  getUnseenConversations,
  removeSeenMessages,
} from "../../redux/actions/messageActions";
import { useTranslation } from "react-i18next";
function EditProfile() {
  const { auth, user } = useSelector((state) => state.authReducer);
  const { unseenLength, unseenNotifications } = useSelector(
    (state) => state.notificationReducer
  );
  const { unseenMessages, unseenConversations, unseenMessagesLength } =
    useSelector((state) => state.messageReducer);
  const { conversations } = useSelector((state) => state.conversationReducer);
  // handle navigate to contacts
  const handleNavigateContacts = () => {
    navigate("/Contacts");
  };
  // handle set current unseen chat & navigate to contacts
  const handleCurrentChat = (unseenConversationId) => {
    let unseenConversation = conversations?.find(
      (c) => c._id === unseenConversationId
    );
    if (unseenConversation) {
      dispatch(setCurrentConversation(unseenConversation, true));
      dispatch(removeSeenMessages(unseenConversationId));
      dispatch(getUnseenConversations());
    } else {
      dispatch(setCurrentUnseenChat(unseenConversationId));
      dispatch(removeSeenMessages(unseenConversationId));
      dispatch(getUnseenConversations());
    }
    navigate("/Contacts");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({ phone: "", email: "", password: "" });
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  // handleChange
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  // set seen notifications
  const handleSetSeenNotofications = () => {
    unseenNotifications.map((el) =>
      dispatch(setSeenNotifications(el._id, user._id))
    );
  };
  // handle edit phone number particular
  const handlePhoneParticular = (e) => {
    e.preventDefault();
    dispatch(editPhoneParticular(data.phone));
  };
  // handle edit phone professional
  const handlePhoneProfessional = (e) => {
    e.preventDefault();
    dispatch(editPhoneProfessional(data.phone));
  };
  // handle edit email adress Particular
  const handleEmailParticular = (e) => {
    e.preventDefault();
    dispatch(editEmailParticular(data.email));
  };
  // handle edit email adress Particular
  const handleEmailProfessional = (e) => {
    e.preventDefault();
    dispatch(editEmailProfessional(data.email));
  };
  // handle edit password particular
  const handlePasswordParticular = (e) => {
    e.preventDefault();
    dispatch(editPasswordParticular(data.password));
  };
  // handle edit password particular
  const handlePasswordProfessional = (e) => {
    e.preventDefault();
    dispatch(editPasswordProfessional(data.password));
  };
  // handle edit photo particular
  const handlePhotoParticular = (e) => {
    e.preventDefault();
    dispatch(editPhotoParticular(file));
  };
  // handle edit photo professional
  const handlePhotoProfessional = (e) => {
    e.preventDefault();
    dispatch(editPhotoProfessional(file));
  };
  // handle edit passeport photo particular
  const handlePassportParticular = (e) => {
    e.preventDefault();
    dispatch(editPassportParticular(file1));
  };
  // handle edit patent photo
  const handlePatentPhoto = (e) => {
    e.preventDefault();
    dispatch(editPatentPhoto(file2));
  };
  // handle edit commercial register photo
  const handleCommercialRegister = (e) => {
    e.preventDefault();
    dispatch(editCommercialRegister(file3));
  };
  // handle navigate to profil
  const handleNavigateProfil = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/profile");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/profile"), 300);
    }
  };
  // handle navigate to commandes
  const handleNavigateCommandes = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/mesCommandes");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/mesCommandes"), 300);
    }
  };
  // handle navigate to edit profile
  const handleNavigateEditProfile = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/editProfile");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/editProfile"), 300);
    }
  };
  // handle navigate to annonces
  const handleNavigateAnnonces = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/mesAnnonces");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/mesAnnonces"), 300);
    }
  };
  // handle navigate to creation blog
  const handleNavigateCreationBlog = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/creationBlog");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/creationBlog"), 300);
    }
  };
  const handleNavigateConfigSlider = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/configSlider");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/configSlider"), 300);
    }
  };
  // handle language
  const [open, setOpen] = useState(false);
  const handleOpenSelectLanguage = () => {
    setOpen((open) => !open);
  };
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  useEffect(() => {
    for (
      let index = 0;
      index < document.getElementsByClassName("lang").length;
      index++
    ) {
      const element = document.getElementsByClassName("lang")[index];
      if (element.value === i18n.language) {
        element.setAttribute("selected", "true");
      }
    }
  }, []);
  return (
    <div className="wrapper">
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-sm-inline-block">
            <Link to="/" className="nav-link">
              {t("dashboard.accueil")}
            </Link>
          </li>
          <li className="nav-item d-sm-inline-block">
            <Link to="/profile" className="nav-link">
              {t("dashboard.profil")}
            </Link>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {auth && (
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-comments" />
                {unseenMessagesLength > 0 && (
                  <span
                    className="badge badge-danger "
                    style={{
                      fontSize: ".7rem",
                      fontWeight: "500",
                      padding: "2px 4px",
                      position: "absolute",
                    }}
                  >{`${unseenMessagesLength}`}</span>
                )}
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">{`${unseenMessagesLength} ${t(
                  "navbar.Nouveaux messages"
                )}`}</span>
                <div className="dropdown-divider" />
                <div className="dropdown-divider" />
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: unseenLength > 2 ? "scroll" : "hidden",
                  }}
                >
                  {unseenConversations?.map((unseenConversation, i) => {
                    let unseenMessage = unseenMessages
                      ?.filter(
                        (message) =>
                          message.conversationId === unseenConversation
                      )
                      .pop();
                    return (
                      <div key={unseenConversation[i]}>
                        <div
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleCurrentChat(unseenConversation)}
                        >
                          <div className="media">
                            <img
                              src={`uploads/${unseenMessage?.sender.imageUrl}`}
                              alt={t("dashboard.avatar d'utilisateur")}
                              className="img-size-50 mr-3 img-circle"
                            />
                            <div className="media-body">
                              <h3 className="dropdown-item-title">
                                <strong>{unseenMessage?.sender.name}</strong>
                                <span className="float-right text-sm text-danger">
                                  <i className="fas fa-star" />
                                </span>
                              </h3>
                              <p className="text-sm">{unseenMessage?.text}</p>
                              <p className="text-sm text-muted">
                                <i className="far fa-clock mr-1" />
                                {format(unseenMessage?.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-divider" />
                      </div>
                    );
                  })}
                </div>
                <div className="dropdown-divider" />
                {unseenMessagesLength > 0 && (
                  <div
                    href="#"
                    className="dropdown-item dropdown-footer"
                    onClick={handleNavigateContacts}
                    style={{ cursor: "pointer" }}
                  >
                    {t("navbar.Voir tous les messages")}
                  </div>
                )}
              </div>
            </li>
          )}
          {auth && (
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell" />
                {unseenLength > 0 && (
                  <span
                    className="badge badge-warning"
                    style={{
                      fontSize: ".7rem",
                      fontWeight: "500",
                      padding: "2px 4px",
                      position: "absolute",
                    }}
                  >
                    {unseenLength}
                  </span>
                )}
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">{`${unseenLength} ${t(
                  "navbar.Notifications"
                )}`}</span>
                <div className="dropdown-divider" />
                <div className="dropdown-divider" />
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: unseenLength > 2 ? "scroll" : "hidden",
                  }}
                >
                  {unseenNotifications?.map((el) => (
                    <div key={el._id}>
                      <div className="dropdown-item">
                        {/* Message Start */}
                        <div className="media">
                          <img
                            src={`uploads/${el.sender.imageUrl}`}
                            alt={t("dashboard.avatar d'utilisateur")}
                            className="img-size-50 mr-3 img-circle"
                          />
                          <div className="media-body">
                            <h3 className="dropdown-item-title">
                              <strong>{el.sender.name}</strong>
                              <span className="float-right text-sm text-warning">
                                <i className="fas fa-star" />
                              </span>
                            </h3>
                            <p className="text-sm">
                              {el.type === 1
                                ? `${el.sender.name} ${t(
                                    "navbar.notification.confirmer son annonce"
                                  )} ${el.announcementId?.count}`
                                : el.type === 2
                                ? `${el.sender.name} ${t(
                                    "navbar.notification.confirmer votre annonce"
                                  )} ${el.announcementId?.count}`
                                : el.type === 3
                                ? `${el.sender.name} ${t(
                                    "navbar.notification.confirmer réception pour son annonce"
                                  )} ${el.announcementId?.count}`
                                : `${el.sender.name} ${t(
                                    "navbar.notification.confirmer réception pour votre annonce"
                                  )} ${el.announcementId?.count}`}
                            </p>
                            <p className="text-sm text-muted">
                              <i className="far fa-clock mr-1" />
                              {format(el.createdAt)}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Message End */}
                      <div className="dropdown-divider" />
                    </div>
                  ))}
                </div>
                <div className="dropdown-divider" />
                {unseenLength > 0 && (
                  <div
                    className="dropdown-item dropdown-footer"
                    style={{ cursor: "pointer" }}
                    onClick={handleSetSeenNotofications}
                  >
                    {t("navbar.Marquer comme vue")}
                  </div>
                )}
              </div>
            </li>
          )}
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
          <li className="nav-item">
            <div
              className={open ? "select-language-expanded" : "select-language"}
            >
              <select
                class={open ? "select-arrow-active" : "select-selected"}
                id={open ? "select-arrow" : null}
                aria-label="Default select example"
                onClick={handleOpenSelectLanguage}
                onChange={handleChangeLanguage}
              >
                <option
                  className="lang"
                  value="fr"
                  // style={{ backgroundColor: "red", color: "#fff" }}
                >
                  FR
                </option>
                <option className="lang" value="en">
                  EN
                </option>
              </select>
            </div>
          </li>
        </ul>
      </nav>
      {/* /.navbar */}
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={`uploads/${user && user.imageUrl}`}
                className="img-circle elevation-2"
                alt={t("dashboard.avatar d'utilisateur")}
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                {user && user.name}
              </a>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item " onClick={handleNavigateProfil}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user" />
                  <p>{t("dashboard.mon profil")}</p>
                </a>
              </li>
              <li className="nav-item" onClick={handleNavigateCommandes}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>{t("dashboard.mes commandes")}</p>
                </a>
              </li>
              <li className="nav-item" onClick={handleNavigateAnnonces}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-copy" />
                  <p>{t("dashboard.mes annonces")}</p>
                </a>
              </li>
              {user?.role === "admin" && (
                <>
                  <li className="nav-item" onClick={handleNavigateCreationBlog}>
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-solid fa-blog" />
                      <p>{t("dashboard.créer un blog")}</p>
                    </a>
                  </li>
                  <li className="nav-item" onClick={handleNavigateConfigSlider}>
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-solid fa-sliders"></i>
                      <p>{t("dashboard.configurer slider")}</p>
                    </a>
                  </li>
                </>
              )}
              <li className="nav-item" onClick={handleNavigateEditProfile}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-edit" />
                  <p>{t("dashboard.editer mon profil")}</p>
                </a>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("dashboard.éditer le Profil")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="fa fa-home"></i> {t("dashboard.accueil")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("dashboard.éditer le Profil")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-6">
                {/* general form elements */}
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">
                      {t("dashboard.éditer les informations")}
                    </h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputPhone">
                          {t("dashboard.nouveau numéro de téléphone")}
                        </label>
                        <div class="input-group mb-3">
                          <input
                            name="phone"
                            type="text"
                            className="form-control"
                            id="exampleInputPhone"
                            placeholder={t(
                              "dashboard.entrer numéro de téléphone"
                            )}
                            onChange={handleChange}
                          />
                          <div class="input-group-append">
                            {user && user.status === "Particular" ? (
                              <button
                                class="btn btn-info"
                                onClick={handlePhoneParticular}
                              >
                                <i className="fas fa-edit">
                                  {" "}
                                  {t("dashboard.éditer")}
                                </i>
                              </button>
                            ) : (
                              <button
                                class="btn btn-info"
                                onClick={handlePhoneProfessional}
                              >
                                <i className="fas fa-edit">
                                  {" "}
                                  {t("dashboard.éditer")}
                                </i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          {t("dashboard.nouvelle adresse email")}
                        </label>
                        <div class="input-group mb-3">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder={t("dashboard.entrer adresse email")}
                            onChange={handleChange}
                          />
                          <div class="input-group-append">
                            {user && user.status === "Particular" ? (
                              <button
                                class="btn btn-info"
                                onClick={handleEmailParticular}
                              >
                                <i className="fas fa-edit">
                                  {" "}
                                  {t("dashboard.éditer")}
                                </i>
                              </button>
                            ) : (
                              <button
                                class="btn btn-info"
                                onClick={handleEmailProfessional}
                              >
                                <i className="fas fa-edit">
                                  {" "}
                                  {t("dashboard.éditer")}
                                </i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          {t("dashboard.nouvelle mot de passe")}
                        </label>
                        <div class="input-group mb-3">
                          <input
                            name="password"
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder={t("dashboard.entrer mot de passe")}
                            onChange={handleChange}
                          />
                          <div class="input-group-append">
                            {user && user.status === "Particular" ? (
                              <button
                                class="btn btn-info"
                                onClick={handlePasswordParticular}
                              >
                                <i className="fas fa-edit">
                                  {" "}
                                  {t("dashboard.éditer")}
                                </i>
                              </button>
                            ) : (
                              <button
                                class="btn btn-info"
                                onClick={handlePasswordProfessional}
                              >
                                <i className="fas fa-edit">
                                  {" "}
                                  {t("dashboard.éditer")}
                                </i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </form>
                </div>
                {/* /.card */}

                {/* Horizontal Form */}
                <div className="card card-success">
                  <div className="card-header">
                    <h3 className="card-title">
                      {t("dashboard.éditer la photo de profil")}
                    </h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form className="form-horizontal">
                    <div className="card-body">
                      <img
                        src={`uploads/${user && user.imageUrl}`}
                        alt="User"
                        style={{ marginBottom: "20px" }}
                      />
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          {t("dashboard.nouvelle photo")}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={(e) => setFile(e.target.files[0])}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                            >
                              {t("dashboard.choisir un fichier")}
                            </label>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "25px 0px 0px 0px",
                          }}
                        >
                          {user && user.status === "Particular" ? (
                            <button
                              className="btn btn-success"
                              style={{ padding: "5px 30px 5px 30px" }}
                              onClick={handlePhotoParticular}
                            >
                              <i className="fas fa-edit">
                                {" "}
                                {t("dashboard.éditer")}
                              </i>
                            </button>
                          ) : (
                            <button
                              className="btn btn-success"
                              style={{ padding: "5px 30px 5px 30px" }}
                              onClick={handlePhotoProfessional}
                            >
                              <i className="fas fa-edit">
                                {" "}
                                {t("dashboard.éditer")}
                              </i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}

                    {/* /.card-footer */}
                  </form>
                </div>
                {/* /.card */}
              </div>
              {/*/.col (left) */}
              {/* right column */}
              <div className="col-md-6">
                {/* Form Element sizes */}
                {user && user.status === "Particular" ? (
                  <div className="card card-success">
                    <div className="card-header">
                      <h3 className="card-title">
                        {t("dashboard.éditer la photo de passeport")}
                      </h3>
                    </div>
                    <div className="card-body">
                      <img
                        src={`uploads/${user && user.imagePassport}`}
                        alt="Passport"
                        style={{ marginBottom: "20px" }}
                      />
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          {t("dashboard.nouvelle photo de passeport")}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              onChange={(e) => setFile1(e.target.files[0])}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                            >
                              {t("dashboard.choisir un fichier")}
                            </label>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "25px 0px 0px 0px",
                          }}
                        >
                          <button
                            className="btn btn-success"
                            style={{ padding: "5px 30px 5px 30px" }}
                            onClick={handlePassportParticular}
                          >
                            <i className="fas fa-edit">
                              {" "}
                              {t("dashboard.éditer")}
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                ) : (
                  <>
                    <div className="card card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          {t("dashboard.éditer la photo de la patente")}
                        </h3>
                      </div>
                      <div className="card-body">
                        <img
                          src={`uploads/${user && user.imagePatent}`}
                          alt="Patent"
                          style={{ marginBottom: "20px" }}
                        />
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">
                            {t("dashboard.nouvelle photo de la patente")}
                          </label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="exampleInputFile"
                                onChange={(e) => setFile2(e.target.files[0])}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFile"
                              >
                                {t("dashboard.choisir un fichier")}
                              </label>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "25px 0px 0px 0px",
                            }}
                          >
                            <button
                              className="btn btn-success"
                              style={{ padding: "5px 30px 5px 30px" }}
                              onClick={handlePatentPhoto}
                            >
                              <i className="fas fa-edit">
                                {" "}
                                {t("dashboard.éditer")}
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.card */}
                    <div className="card card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          {t(
                            "dashboard.éditer la photo du registre de commerce"
                          )}
                        </h3>
                      </div>
                      <div className="card-body">
                        <img
                          src={`uploads/${
                            user && user.imageCommercialRegister
                          }`}
                          alt="Commercial Register"
                          style={{ marginBottom: "20px" }}
                        />
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">
                            {t(
                              "dashboard.nouvelle photo du registre de commerce"
                            )}
                          </label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="exampleInputFile"
                                onChange={(e) => setFile3(e.target.files[0])}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFile"
                              >
                                {t("dashboard.choisir un fichier")}
                              </label>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "25px 0px 0px 0px",
                            }}
                          >
                            <button
                              className="btn btn-success"
                              style={{ padding: "5px 30px 5px 30px" }}
                              onClick={handleCommercialRegister}
                            >
                              <i className="fas fa-edit">
                                {" "}
                                {t("dashboard.éditer")}
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.card */}
                  </>
                )}
              </div>
              {/*/.col (right) */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 3.2.0
        </div>
        <strong>
          Copyright © 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.
        </strong>{" "}
        All rights reserved.
      </footer>
      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
      {/* /.control-sidebar */}
    </div>
  );
}

export default EditProfile;
