import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { login } from "../redux/actions/authActions";
import "../App.css";
import { useTranslation } from "react-i18next";
import { setAlert } from "../redux/actions/alertActions";

function Login() {
  const [data, setData] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // handleChange
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  // handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data.email) {
      dispatch(setAlert("Entrer une adresse email valide!", "danger"));
    } else if (!data.password) {
      dispatch(setAlert("Votre mot de passe est requise!", "danger"));
    } else {
      
      dispatch(login(data, navigate));
    }
  };

  return (
    <>
      <Navbar />
      <div>
        <section
          className="pt-5 pb-5 mt-0 align-items-center d-flex bg-dark"
          style={{
            minHeight: "100vh",
            backgroundSize: "cover",
            backgroundImage:
              "url(https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=c0d43804e2c7c93143fe8ff65398c8e9)",
          }}
        >
          <div className="container-fluid">
            <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">
              <div className="col-12 col-md-4 col-lg-3   h-50 ">
                <div className="card shadow">
                  <div className="card-body mx-auto">
                    <h4 className="login">{t("login.connection")}</h4>
                    <p className="text">{t("login.accueil")}</p>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-envelope" />{" "}
                          </span>
                        </div>
                        <input
                          name="email"
                          className="form-control"
                          placeholder={t("login.email")}
                          type="email"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-lock" />{" "}
                          </span>
                        </div>
                        <input
                          name="password"
                          className="form-control"
                          placeholder={t("login.password")}
                          type="password"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {t("login.se connecter")}
                        </button>
                      </div>
                      <p className="text">
                        {t("login.account")}
                        <Link to="/register"> {t("login.register")} </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Login;
