import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import {
  setArrival,
  setCurrentPage,
  setDepart,
  setLoading,
  setSearchTrue,
} from "../redux/actions/announcementActions";
import {
  incrementEtape,
  initialiseEtape,
} from "../redux/actions/sliderActions";
import "./Main.css";

function Main() {
  // slider
  let img__slider = document.getElementsByClassName("img__slider");
  // let etape = 0;
  const { etape } = useSelector((state) => state.sliderReducer);
  let nbr__img = img__slider.length;
  let precedent = document.querySelector(".precedent");
  let suivant = document.querySelector(".suivant");
  function enleverActiveImages() {
    for (let i = 0; i < nbr__img; i++) {
      img__slider[i]?.classList.remove("active");
    }
  }
  suivant?.addEventListener("click", function () {
    etape++;
    if (etape >= nbr__img) {
      etape = 0;
    }
    enleverActiveImages();
    img__slider[etape].classList.add("active");
  });
  precedent?.addEventListener("click", function () {
    etape--;
    if (etape < 0) {
      etape = nbr__img - 1;
    }
    enleverActiveImages();
    img__slider[etape].classList.add("active");
  });
  useEffect(() => {
    if (etape >= nbr__img) {
      dispatch(initialiseEtape());
    }
  }, [etape]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(incrementEtape());
    }, 6000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    enleverActiveImages();
    setTimeout(() => img__slider[etape]?.classList.add("active"), 1000);
  }, [etape]);
  // setInterval(function () {
  //   etape++;
  //   dispatch(incrementEtape());
  //   if (etape >= nbr__img) {
  //     // etape = 0;
  //     dispatch(initialiseEtape());
  //   }
  //   console.log(etape);
  //   enleverActiveImages();
  //   img__slider[etape]?.classList.add("active");
  // }, 5000);
  // get announcements
  const { announcements } = useSelector((state) => state.announcementReducer);
  // handle search
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSearch = (depart, arrival) => {
    dispatch(setLoading());
    dispatch(setCurrentPage(0));
    dispatch(setSearchTrue());
    dispatch(setDepart(depart));
    dispatch(setArrival(arrival));
    navigate("/Announcements");
  };
  const { sliderImages } = useSelector((state) => state.sliderReducer);
  useEffect(() => {
    img__slider[0]?.classList.add("active");
  }, [sliderImages]);
  // useEffect(() => {
  //   return () => {
  //     enleverActiveImages();
  //   };
  // }, []);
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      {/* slider start */}
      {sliderImages && sliderImages.length !== 0 && (
        <div className="slide">
          <div className="slider">
            {sliderImages?.map((img, i) => (
              <img
                src={`slider/${img}`}
                alt={`slider${i}`}
                className={"img__slider"}
              />
            ))}
            <div className="suivant">
              <i class="fas fa-chevron-circle-right"></i>
            </div>
            <div className="precedent">
              <i class="fas fa-chevron-circle-left"></i>
            </div>
          </div>
          <img
            src="sliderBackground/slider.png"
            className="img1"
            alt="slider-background"
          />
        </div>
      )}
      {/* slider end */}
      {/* solution start */}
      <div className="solution">
        <div className="contain">
          <div className="justify-content-center">
            <div className="content2">
              <h2 className="solution-text">
                {t("main.title1.La solution d'expédition")}{" "}
                <span className="home-text">
                  {t("main.title1.révolutionnaire")}
                </span>
              </h2>
              <hr className="solution-sep" />
            </div>
            <div className="content2">
              <p>{t("main.introduction1")}</p>
            </div>
          </div>
        </div>
        <div className="content3">
          <div className="cell25 child1">
            <img src="images/Envoyer.jpg" alt="" />
            <h4>
              <strong>{t("main.Simple et Pratique")}</strong>
            </h4>
            <p>{t("main.paragraph1")}</p>
          </div>
          <div className="cell25 child2">
            <img src="images/livraison.jpg" alt="" />
            <h4>
              <strong>{t("main.Moins Cher")}</strong>
            </h4>
            <p>{t("main.paragraph2")}</p>
          </div>
          <div className="cell25 child3">
            <img src="images/rapide-et-simple.jpg" alt="" />
            <h4>
              <strong>{t("main.Rapide")}</strong>
            </h4>
            <p>{t("main.paragraph3")}</p>
          </div>
        </div>
      </div>
      {/* solution end */}
      {/* jumbotron start */}
      <div className="jumbotron">
        <div className="justify-content-center">
          <div className="content1">
            <h1 className="home-title">{t("main.title2")}</h1>
            <h2 className="home-subtitle">
              {t("main.introduction2.La solution écologique pour")}{" "}
              <span className="home-text">
                <b>{t("main.introduction2.particuliers")}</b>
              </span>{" "}
              {t("main.introduction2.et")}{" "}
              <span className="home-text">
                <b>{t("main.introduction2.professionnels")}</b>
              </span>
            </h2>
          </div>
          <div className="content1">
            <img
              src="image-acceuil.jpg"
              className="home-image"
              alt="page-acceuil"
            />
          </div>
        </div>
      </div>
      {/* jumbotron end */}
      {/* how it works start */}
      <div className="howitworks">
        <div className="contain">
          <h2 className="solution-text">
            {t("main.Comment")}{" "}
            <span className="home-text">{t("main.ça marche ?")}</span>
          </h2>
          <hr className="solution-sep" />
        </div>
      </div>
      {/* how it works end */}
      {/* espace dédié start */}
      <div className="espace">
        <div className="contain"></div>
      </div>
      {/* espace dédié end */}
      {/* next departures start */}
      {announcements && announcements.length >= 4 && (
        <div className="nextDepartures">
          <div className="contain" style={{ maxWidth: "1280px" }}>
            <h3>{t("main.Les prochains départs")}</h3>
            <div className="departures">
              <div className="cell24">
                <div className="userDeparts">
                  <div className="userImg avatarCustom">
                    <span className="colispoLink">
                      {announcements && announcements[0].userId.name[0]}
                    </span>
                  </div>
                  <div className="userName">
                    <span className="colispoLink">
                      {announcements && announcements[0].userId.name}
                    </span>{" "}
                  </div>
                  <div
                    role="button"
                    className="direction"
                    onClick={() =>
                      handleSearch(
                        announcements[0].depart,
                        announcements[0].arrival
                      )
                    }
                  >
                    <span className="ville">
                      <strong>
                        {announcements && announcements[0].depart}
                      </strong>
                    </span>
                    <span className="icon"></span>
                    <span className="ville">
                      <strong>
                        {announcements && announcements[0].arrival}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="cell24">
                <div className="userDeparts">
                  <div className="userImg avatarCustom">
                    <span className="colispoLink">
                      {announcements && announcements[1].userId.name[0]}
                    </span>
                  </div>
                  <div className="userName">
                    <span className="colispoLink">
                      {announcements && announcements[1].userId.name}
                    </span>{" "}
                  </div>
                  <div
                    role="button"
                    className="direction"
                    onClick={() =>
                      handleSearch(
                        announcements[1].depart,
                        announcements[1].arrival
                      )
                    }
                  >
                    <span className="ville">
                      <strong>
                        {announcements && announcements[1].depart}
                      </strong>
                    </span>
                    <span className="icon"></span>
                    <span className="ville">
                      <strong>
                        {announcements && announcements[1].arrival}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="cell24">
                <div className="userDeparts">
                  <div className="userImg avatarCustom">
                    <span className="colispoLink">
                      {announcements && announcements[2].userId.name[0]}
                    </span>
                  </div>
                  <div className="userName">
                    <span className="colispoLink">
                      {announcements && announcements[2].userId.name}
                    </span>{" "}
                  </div>
                  <div
                    role="button"
                    className="direction"
                    onClick={() =>
                      handleSearch(
                        announcements[2].depart,
                        announcements[2].arrival
                      )
                    }
                  >
                    <span className="ville">
                      <strong>
                        {announcements && announcements[2].depart}
                      </strong>
                    </span>
                    <span className="icon"></span>
                    <span className="ville">
                      <strong>
                        {announcements && announcements[2].arrival}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="cell24">
                <div className="userDeparts">
                  <div className="userImg avatarCustom">
                    <span className="colispoLink">
                      {announcements && announcements[3].userId.name[0]}
                    </span>
                  </div>
                  <div className="userName">
                    <span className="colispoLink">
                      {announcements && announcements[3].userId.name}
                    </span>{" "}
                  </div>
                  <div
                    role="button"
                    className="direction"
                    onClick={() =>
                      handleSearch(
                        announcements[3].depart,
                        announcements[3].arrival
                      )
                    }
                  >
                    <span className="ville">
                      <strong>
                        {announcements && announcements[3].depart}
                      </strong>
                    </span>
                    <span className="icon"></span>
                    <span className="ville">
                      <strong>
                        {announcements && announcements[3].arrival}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* next departures end */}
      {/* nos clients témoignent start */}
      <div className="user_feedback">
        <div className="contain" style={{ paddingBottom: "100px" }}>
          <h2 className="section-heading">
            {t("main.Nos clients")}{" "}
            <span className="home-text">{t("main.témoignent")}</span>
          </h2>
          <hr className="solution-sep" />
          <div className="justify-content">
            <div className="box-client">
              <div className="box-client-white">
                <div className="justify-content">
                  <div className="col1">
                    <img
                      lazy-img="clients/gens1.jpg"
                      className="img-circle-client"
                      src="clients/gens1.jpg"
                      alt="gens 1"
                    />
                  </div>
                  <div className="col2">
                    <span>
                      <b>Taha</b>
                    </span>
                    <br />
                    <span className="client-type">
                      {t("main.Expéditeur ColisPO")}
                    </span>
                  </div>
                </div>
                <p className="texte-client">{t("main.paragraph4")}</p>
              </div>
            </div>
            <div className="box-client">
              <div className="box-client-white">
                <div className="justify-content">
                  <div className="col1">
                    <img
                      lazy-img="clients/gens2.jpg"
                      className="img-circle-client"
                      src="clients/gens2.jpg"
                      alt="gens 2"
                    />
                  </div>
                  <div className="col2">
                    <span>
                      <b>Caroline</b>
                    </span>
                    <br />
                    <span className="client-type">
                      {t("main.Voyageuse ColisPO")}
                    </span>
                  </div>
                </div>
                <p className="texte-client">{t("main.paragraph5")}</p>
              </div>
            </div>
            <div className="box-client">
              <div className="box-client-white">
                <div className="justify-content">
                  <div className="col1">
                    <img
                      lazy-img="clients/gens3.jpg"
                      className="img-circle-client"
                      src="clients/gens3.jpg"
                      alt="gens 3"
                    />
                  </div>
                  <div className="col2">
                    <span>
                      <b>François</b>
                    </span>
                    <br />
                    <span className="client-type">
                      {t("main.Voyageur Régulier")}
                    </span>
                  </div>
                </div>
                <p className="texte-client">{t("main.paragraph6")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* nos clients témoignent end */}
      {/* bottom bar start */}
      <div className="bottomBar">
        <div className="blockNotif">
          <div className="contain1">
            <div className="grid-expanded">
              <div className="cell26">
                <div className="notifBox efficace">
                  <h4>{t("main.efficacité")}</h4>
                  <p>{t("main.paragraph7")}</p>
                </div>
              </div>
              <div className="cell26">
                <div className="notifBox confiance">
                  <h4>{t("main.confiance")}</h4>
                  <p>{t("main.paragraph8")}</p>
                </div>
              </div>
              <div className="cell26">
                <div className="notifBox paiementSécurisé">
                  <h4>{t("main.paiements sécurisés")}</h4>
                  <p>{t("main.paragraph9")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom bar end */}
      <Footer />
    </>
  );
}

export default Main;
