import { GET_NOTIFICATIONS, GET_UNSEEN_NOTIFICATIONS, SET_NEW_NOTIFICATION, SET_SOCKET } from "../types/notificationTypes";


const initialState={
    socket:null,
    notifications:null,
    unseenNotifications:null,
    unseenLength:null
}

const notificationReducer=(state=initialState, {type,payload})=>{
    switch (type) {
        case SET_SOCKET:
            return {...state, socket:payload}
        case GET_NOTIFICATIONS:
            return {...state, notifications:payload}
        case SET_NEW_NOTIFICATION:
            return {...state, notifications:[...state.notifications, payload]}
        case GET_UNSEEN_NOTIFICATIONS:
            return {...state, unseenNotifications:payload, unseenLength:payload.length}
        default:
            return state;
    }
}

export default notificationReducer