import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmCommande,
  setCommande,
} from "../../redux/actions/commandeActions";
import { setNotification } from "../../redux/actions/notificationActions";
import "./commande.css";

function Commande() {
  const { user } = useSelector((state) => state.authReducer);
  const { currentConversation } = useSelector(
    (state) => state.conversationReducer
  );
  const { otherUser } = useSelector((state) => state.messageReducer);
  const { socket } = useSelector((state) => state.notificationReducer);
  const dispatch = useDispatch();
  const handleSetCommande = () => {
    if (currentConversation.announcementId?.userId === user?._id) {
      dispatch(
        setCommande(
          user?._id,
          otherUser?._id,
          currentConversation.announcementId?._id,
          currentConversation?._id
        )
      );
      const notification = {
        conversationId: currentConversation._id,
        announcementId: currentConversation.announcementId._id,
        sender: user._id,
        receiver: otherUser._id,
        type: 1,
      };
      dispatch(setNotification(notification));
      socket.current.emit("sendNotification", {
        conversationId: currentConversation._id,
        announcementId: currentConversation.announcementId._id,
        sender: user._id,
        receiver: otherUser._id,
        imageUrl: user.imageUrl,
        type: 1,
        model_type:
          user?.status === "Particular" ? "UserParticular" : "UserProfessional",
      });
    } else {
      dispatch(
        setCommande(
          otherUser?._id,
          user?._id,
          currentConversation.announcementId?._id,
          currentConversation?._id
        )
      );
      const notification = {
        conversationId: currentConversation?._id,
        announcementId: currentConversation.announcementId?._id,
        sender: user?._id,
        receiver: otherUser?._id,
        type: 2,
      };
      dispatch(setNotification(notification));
      socket.current.emit("sendNotification", {
        conversationId: currentConversation?._id,
        announcementId: currentConversation.announcementId?._id,
        sender: user?._id,
        receiver: otherUser?._id,
        imageUrl: user?.imageUrl,
        type: 2,
        model_type:
          user.status === "Particular" ? "UserParticular" : "UserProfessional",
      });
    }
  };
  const handleConfirmCommande = () => {
    if (currentConversation.announcementId?.userId === user?._id) {
      dispatch(
        confirmCommande(
          user._id,
          otherUser._id,
          currentConversation.announcementId._id,
          currentConversation._id
        )
      );
      const notification = {
        conversationId: currentConversation._id,
        announcementId: currentConversation.announcementId._id,
        sender: user._id,
        receiver: otherUser._id,
        type: 3,
      };
      dispatch(setNotification(notification));
      socket.current.emit("sendNotification", {
        conversationId: currentConversation._id,
        announcementId: currentConversation.announcementId._id,
        sender: user._id,
        receiver: otherUser._id,
        imageUrl: user.imageUrl,
        type: 3,
        model_type:
          user.status === "Particular" ? "UserParticular" : "UserProfessional",
      });
    } else {
      dispatch(
        confirmCommande(
          otherUser?._id,
          user?._id,
          currentConversation.announcementId?._id,
          currentConversation?._id
        )
      );
      const notification = {
        conversationId: currentConversation?._id,
        announcementId: currentConversation.announcementId?._id,
        sender: user?._id,
        receiver: otherUser?._id,
        type: 4,
      };
      dispatch(setNotification(notification));
      socket.current.emit("sendNotification", {
        conversationId: currentConversation?._id,
        announcementId: currentConversation.announcementId?._id,
        sender: user?._id,
        receiver: otherUser?._id,
        imageUrl: user?.imageUrl,
        type: 4,
        model_type:
          user.status === "Particular" ? "UserParticular" : "UserProfessional",
      });
    }
  };
  const { t } = useTranslation();
  return (
    <div className="Commande">
      <div className="CommmandeWrapper">
        <div className="confirm">
          <h2> {t("Contacts.accord commande")}</h2>
        </div>
        <button className="commander" onClick={handleSetCommande}>
          {t("Contacts.commander")}
        </button>
        <div className="confirm">
          <h2> {t("Contacts.reçu colis")}</h2>
        </div>
        <button className="commander" onClick={handleConfirmCommande}>
          {t("Contacts.confirmer")}
        </button>
      </div>
    </div>
  );
}

export default Commande;
