import useaxios from "../../utils/customAxios";
import {
  GET_SLIDER,
  INCREMENT_ETAPE,
  INITIALISE_ETAPE,
} from "../types/sliderTypes";
const api=useaxios()
// get slider images
export const getSliderImages = () => async (dispatch) => {
  try {
    const res = await api.get("/api/slider/getImagesSlider");
    dispatch({
      type: GET_SLIDER,
      payload: res.data.Files,
    });
  } catch (error) {
    console.log({ msg: "could not get slider images", error });
  }
};
// delete slider image
export const deleteSliderImage = (imageName) => async (dispatch) => {
  
  try {
    const res = await api.delete(
      `/api/slider/deleteImageSlider/${imageName}`
    );
    dispatch(getSliderImages());
  } catch (error) {
    console.log({ msg: "could not delete slider image", error });
  }
};
// edit slider image
export const editSliderImage = (imageName, newName) => async (dispatch) => {
  
  try {
    const res = await api.put(
      `/api/slider/editSliderName/${imageName}`,
      { newName }
    );
    dispatch(getSliderImages());
  } catch (error) {
    console.log({ msg: "could not edit slider image", error });
  }
};
// add slider
export const addSlider = (slider, setPhotoSlider) => async (dispatch) => {
  
  const data = new FormData();
  data.append("slider", slider);
  try {
    const res = await api.post("/api/slider/uploadImageSlider", data);
    setPhotoSlider(null);
    dispatch(getSliderImages());
  } catch (error) {
    console.log({ msg: "could not add slider", error });
  }
};

export const incrementEtape = () => {
  return { type: INCREMENT_ETAPE };
};

export const initialiseEtape = () => {
  return { type: INITIALISE_ETAPE };
};
