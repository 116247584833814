import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Error from "./Components/Error";
import Login from "./Pages/Login";
import Main from "./Pages/Main";
import NotFound from "./Pages/NotFound";
import { getCurrentUser } from "./redux/actions/authActions";
import PrivateRoute from "./router/PrivateRoute";
import Dashboard from "./Pages/Dashboard";
import EditProfile from "./Pages/Dashboard/EditProfile";
import Paiement from "./Pages/Paiement";
import RegisterChoice from "./Pages/RegisterChoice";
import RegisterParticular from "./Pages/RegisterParticular";
import RegisterProfessional from "./Pages/RegisterProfessional";
import ActivationPage from "./Pages/ActivationPage";
import ActivationNewEmail from "./Pages/ActivationNewEmail";
import SendColis from "./Pages/SendColis";
import Contact from "./Pages/Contact";
import MesCommandes from "./Pages/Dashboard/MesCommandes";
import MesAnnonces from "./Pages/Dashboard/MesAnnonces";
import { io } from "socket.io-client";
import {
  getArrivalNotification,
  getNotifications,
  getUnseenNotifications,
  setSocket,
} from "./redux/actions/notificationActions";
import { setAlert } from "./redux/actions/alertActions";
import {
  getArrivalMessage,
  getUnseenConversations,
  getUnseenMessages,
  setUnseenMessage,
} from "./redux/actions/messageActions";
import Announcements from "./Pages/Announcements";
import { firstAnnouncements } from "./redux/actions/announcementActions";
import TermesConditions from "./Pages/TermesConditions";
import Blog from "./Pages/Blog";
import CreationBlog from "./Pages/Dashboard/CreationBlog";
import AdminRoute from "./router/AdminRoute";
import BlogDetails from "./Pages/BlogDetails";
import { getBlogs } from "./redux/actions/blogActions";
import { getSliderImages } from "./redux/actions/sliderActions";
import ConfigSlider from "./Pages/Dashboard/ConfigSlider";

function App() {
  const dispatch = useDispatch();
  const socket = useRef();
  const { user } = useSelector((state) => state.authReducer);
  const [arrivalNotification, setArrivalNotification] = useState(null);
  const { notifications } = useSelector((state) => state.notificationReducer);
  const { unseenMessages, unseenMessagesLength } = useSelector(
    (state) => state.messageReducer
  );
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const { currentConversation } = useSelector(
    (state) => state.conversationReducer
  );
  // get current user
  // useEffect(() => {
  //   dispatch(getCurrentUser());
  // }, []);
  // get socket from server
  // useEffect(() => {
  //   socket.current = io("wss://socket.colispo.com");
  //   dispatch(setSocket(socket));
  // }, [socket]);
  // connect user to socket
  // useEffect(() => {
  //   socket?.current.emit("addUser", user?._id);
  //   socket?.current.on("getUsers", {
  //     /* users=>{
  //       console.log(users)
  //   } */
  //   });
  // }, [socket?.current?.connected, user]);
  // get notifications
  // useEffect(() => {
  //   user && dispatch(getNotifications(user?._id));
  // }, [user]);
  // get a notification from a user with socket
  // useEffect(() => {
  //   socket.current.on("getNotification", (data) => {
  //     setArrivalNotification({
  //       conversationId: data.conversationId,
  //       sender: data.sender,
  //       receiver: data.receiver,
  //       receiverImage: data.imageUrl,
  //       type: data.type,
  //       model_type: data.model_type,
  //       createdAt: new Date().toISOString(),
  //     });
  //     dispatch(setAlert("Vous avez une nouvelle notification!", "success"));
  //   });
  // }, []);
  // useEffect(() => {
  //   arrivalNotification &&
  //     dispatch(getArrivalNotification(arrivalNotification));
  // }, [arrivalNotification]);
  // useEffect(() => {
  //   user && dispatch(getUnseenNotifications(user?._id));
  // }, [notifications, arrivalNotification]);
  // //
  // useEffect(() => {
  //   socket?.current.on("getMessage", (data) => {
  //     data.createdAt = new Date().toISOString();
  //     setArrivalMessage({
  //       conversationId: data.conversationId,
  //       sender: data.sender,
  //       receiver: data.receiver,
  //       senderName: data.senderName,
  //       senderImageUrl: data.senderImageUrl,
  //       text: data.text,
  //       model_type: data.model_type,
  //       seen: data.seen,
  //       createdAt: data.createdAt,
  //     });
  //     dispatch(setUnseenMessage(data));
  //   });
  // }, []);
  // // Set arrival message
  // useEffect(() => {
  //   arrivalMessage &&
  //     arrivalMessage.conversationId === currentConversation?._id &&
  //     currentConversation?.members.includes(arrivalMessage.sender) &&
  //     dispatch(getArrivalMessage(arrivalMessage));
  // }, [arrivalMessage, currentConversation]);
  // // Get unseen messages
  // useEffect(() => {
  //   user && dispatch(getUnseenMessages());
  // }, [user]);
  // useEffect(() => {
  //   unseenMessages && dispatch(getUnseenConversations());
  // }, [unseenMessages]);
  // // get unseen conversations to update date of unseen messages
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     unseenMessagesLength && dispatch(getUnseenConversations());
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, []);
  // // get announcements
  // const { firstPage } = useSelector((state) => state.announcementReducer);
  // useEffect(() => {
  //   dispatch(firstAnnouncements(firstPage));
  // }, []);
  // // get the blogs of ColisPO
  // useEffect(() => {
  //   dispatch(getBlogs());
  // }, []);
  // // get slider images
  // useEffect(() => {
  //   dispatch(getSliderImages());
  // }, []);

  return (
    <div className="App">
      <Error />
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/Announcements" element={<Announcements />}></Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<RegisterChoice />} />
        <Route path="register-particular" element={<RegisterParticular />} />
        <Route
          path="register-professional"
          element={<RegisterProfessional />}
        />
        <Route
          path="profile"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="editProfile"
          element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          }
        />
        <Route path="SendColis" element={<SendColis />} />
        <Route path="confirm/:activationcode" element={<ActivationPage />} />
        <Route
          path="confirmNewEmail/:activationcode"
          element={<ActivationNewEmail />}
        />
        <Route path="paiement" element={<Paiement />} />
        <Route path="Termes&Conditions" element={<TermesConditions />} />
        <Route path="Blog" element={<Blog />} />
        <Route path=":id" element={<BlogDetails />} />
        <Route
          path="Contacts"
          element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          }
        />
        <Route
          path="mesCommandes"
          element={
            <PrivateRoute>
              <MesCommandes />
            </PrivateRoute>
          }
        />
        <Route
          path="mesAnnonces"
          element={
            <PrivateRoute>
              <MesAnnonces />
            </PrivateRoute>
          }
        />
        <Route
          path="creationBlog"
          element={
            <AdminRoute>
              <CreationBlog />
            </AdminRoute>
          }
        />
        <Route
          path="configSlider"
          element={
            <AdminRoute>
              <ConfigSlider />
            </AdminRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
