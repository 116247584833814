import { GET_CONVERSATIONS, SET_CURRENT_CONVERSATION } from "../types/conversationTypes"
import useaxios from "../../utils/customAxios";
const api=useaxios()
// get conversations
export const getConversations = (id)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/conversations/${id}`)
        dispatch({
            type:GET_CONVERSATIONS,
            payload:res.data
        })
    } catch (error) {
        console.log({msg:'could not get conversations', error})
    }
}
// set current chat
export const setCurrentChat = (currentUserId, friendId, announcementId) =>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/conversations/${currentUserId}/${friendId}/${announcementId}`)
        if(res.data.conversation[0]){
            dispatch({
                type:SET_CURRENT_CONVERSATION,
                payload:res.data.conversation[0]
            })
        }
        if(!res.data.conversation[0]){
            let res1=await api.post('/api/conversations', {senderId:currentUserId,receiverId:friendId,announcementId})
            dispatch({
                type:SET_CURRENT_CONVERSATION,
                payload:res1.data
            })
        }
    } catch (error) {
        console.log({msg:'could not get current conversation', error})
    }
}
// choose a conversation and set seen messages
export const setCurrentUnseenChat=(unseenConversationId)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`api/conversations/unseenConversation/${unseenConversationId}`)
        const res1=await api.put(`/api/messages/setSeenMessages/${unseenConversationId}`, {unseenConversationId})
        dispatch({
            type:SET_CURRENT_CONVERSATION,
            payload:res.data.conversation
        })
    } catch (error) {
        console.log({msg:'could not get unseen current conversation', error})
    }
}
// set current seen chat and set seen messages
export const setCurrentConversation=(current,type)=>async(dispatch)=>{
    let currentId=current._id;
    
    try {
        const res=await api.put(`/api/messages/setSeenMessages/${current._id}`, {currentId})
        if (type){
            dispatch({
                type:SET_CURRENT_CONVERSATION,
                payload:current
            })
        }
    } catch (error) {
        console.log({msg:'could not set seen messages', error})
    }
}
// set current chat
export const setCurrentSeenChat=(current)=>{
    return{
        type:SET_CURRENT_CONVERSATION,
        payload:current
    }
}
