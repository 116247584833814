import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentChat } from "../redux/actions/conversationAction";
import "./Announcement.css";
import { AiFillDelete } from "react-icons/ai";
import { deleteAnnouncement } from "../redux/actions/announcementActions";
import { setAlert } from "../redux/actions/alertActions";
import { useTranslation } from "react-i18next";

function Announcement({ announcement }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authReducer);
  // handle set current chat
  const handleCurrentChat = () => {
    if (user._id === announcement.userId._id) {
      dispatch(setAlert("C'est votre annonce"));
    } else {
      dispatch(
        setCurrentChat(user._id, announcement.userId._id, announcement._id)
      );
      navigate("/Contacts");
    }
  };
  const { t } = useTranslation();
  return (
    <div>
      <div className="searchResult boxesView">
        <div className="box searchBoxTwo">
          {user?.role === "admin" && (
            <button
              className="fillDeleteAnnouncement"
              onClick={() => dispatch(deleteAnnouncement(announcement._id))}
            >
              <AiFillDelete />
            </button>
          )}
          <div className="grid">
            <div className="cel9">
              <span className="Notif Yellow">
                {t(`EnvoyerColis.${announcement.typeService}`)}
              </span>
              <div className="contBox">
                <div className="grid1">
                  <div className="cel6">
                    <p className="Objet">
                      {t("Announcement.Types")}{" "}
                      <strong>
                        {t(`EnvoyerColis.${announcement.typeColis}`)}
                      </strong>
                    </p>
                    <p className="Dimension">
                      {t("Announcement.Dimension")}{" "}
                      <strong>
                        {t(`EnvoyerColis.${announcement.dimension}`)}
                      </strong>
                    </p>
                  </div>
                  <div className="cel6" id="cell">
                    <div className="cellFromToMobile">
                      <div className="from-to">
                        <span className="from">
                          <strong> {announcement.depart} </strong>
                        </span>
                        <span className="to">
                          <strong> {announcement.arrival} </strong>
                        </span>
                      </div>
                    </div>
                    <p className="Dimension">
                      {t("Announcement.Poid")}{" "}
                      <strong>{announcement.approximateWeight}</strong>
                    </p>
                  </div>
                </div>
              </div>
              {/*.contBox*/}
            </div>
            <div className="cel3">
              <div className="CommisionBlock">
                <p>
                  {t("Announcement.Commission proposée")}{" "}
                  <strong>{`${announcement.commission} €`}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="userData">
          <div className="grid2">
            <div className="cell" style={{ display: "flex" }}>
              <div className="userTopBox">
                <div className="avatar">
                  <a href className="jwebiLink"></a>
                </div>
                <span className="badge maxi" />
              </div>
              <p className="Objet">
                {t("Announcement.Date")}{" "}
                <strong>{announcement.date.split("T")[0]}</strong>
              </p>
            </div>
            <div className="cell infoMobile">
              <h2 className="userName"> </h2>
            </div>
            <div className="cell mobileContact">
              <div className="userContactItmes">
                <span
                  className="contactItem carte"
                  data-tooltip="Pièce d’identité vérifiée"
                />
                <span
                  className="contactItem mail"
                  data-tooltip="Adresse email vérifié"
                />
              </div>
            </div>
            <div className="cell fbMobile"></div>
            <div className="cell right">
              <button
                className="btnLink yellowStyle"
                onClick={handleCurrentChat}
              >
                {t("Announcement.Contacter")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
