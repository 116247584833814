import {
  GET_SLIDER,
  INCREMENT_ETAPE,
  INITIALISE_ETAPE,
} from "../types/sliderTypes";

const initialState = {
  sliderImages: null,
  etape: 0,
};

const sliderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SLIDER:
      return { ...state, sliderImages: payload };
    case INCREMENT_ETAPE:
      return { ...state, etape: state.etape + 1 };
    case INITIALISE_ETAPE:
      return { ...state, etape: 0 };
    default:
      return state;
  }
};
export default sliderReducer;
