export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const SET_LOADING = "SET_LOADING";
export const SET_CURRENTPAGE = "SET_CURRENTPAGE";
export const SET_SEARCH_TRUE = "SET_SEARCH_TRUE";
export const GET_INNER_ANNOUNCEMENTS = "GET_INNER_ANNOUNCEMENTS";
export const SET_INNER_LOADING = "SET_INNER_LOADING";
export const SET_INNER_CURRENTPAGE = "SET_INNER_CURRENTPAGE";
export const SET_DEPART = "SET_DEPART";
export const SET_ARRIVAL = "SET_ARRIVAL";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
