import useaxios from "../../utils/customAxios";

import {
  GET_ANNOUNCEMENTS,
  SET_CURRENTPAGE,
  SET_LOADING,
  SET_SEARCH_TRUE,
  GET_INNER_ANNOUNCEMENTS,
  SET_INNER_LOADING,
  SET_INNER_CURRENTPAGE,
  SET_DEPART,
  SET_ARRIVAL,
  DELETE_ANNOUNCEMENT,
} from "../types/announcementTypes";
import { setAlert } from "./alertActions";
const api=useaxios()
export const createAnnouncement =
  (
    depart,
    arrival,
    date,
    typeService,
    typeColis,
    approximateWeight,
    file,
    dimension,
    description,
    email,
    commission,
    totalCommission
  ) =>
  async (dispatch) => {
    const data = new FormData();
    data.append("depart", depart);
    data.append("arrival", arrival);
    data.append("date", date);
    data.append("typeService", typeService);
    data.append("typeColis", typeColis);
    data.append("approximateWeight", approximateWeight);
    data.append("myFile", file);
    data.append("dimension", dimension);
    data.append("description", description);
    data.append("email", email);
    data.append("commission", commission);
    data.append("totalCommission", totalCommission);
    
    try {
      const res = await api.post(
        "/api/announcement/createAnnouncement",
        data
      );
    } catch (error) {
      error.response.data.errors.forEach((err) =>
        dispatch(setAlert(err.msg, "danger"))
      );
      console.log({ msg: "could not post announcement", error });
    }
  };
// get first page
export const firstAnnouncements = (currentPage) => async (dispatch) => {
  try {
    const res = await api.post(`/api/announcement/${currentPage}`);
    dispatch({
      type: GET_ANNOUNCEMENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log({ msg: "could not get announcements", error });
  }
};
// get first page of inner announcements
export const firstInnerAnnouncements = (currentPage) => async (dispatch) => {
  
  try {
    const res = await api.post(
      `/api/announcement/innerAnnouncements/${currentPage}`,
      { currentPage }
    );
    dispatch({
      type: GET_INNER_ANNOUNCEMENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log({ msg: "could not get my announcements", error });
  }
};
export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};
export const setInnerLoading = () => {
  return {
    type: SET_INNER_LOADING,
  };
};
export const getAnnouncements =
  (
    firstPage,
    depart1,
    arrival1,
    date1,
    typeService1,
    dimension1,
    weight1,
    category1
  ) =>
  async (dispatch) => {
    try {
      const res = await api.post(`/api/announcement/${firstPage}`, {
        depart1,
        arrival1,
        date1,
        typeService1,
        dimension1,
        weight1,
        category1,
      });
      dispatch({
        type: GET_ANNOUNCEMENTS,
        payload: res.data,
      });
    } catch (error) {
      console.log({ msg: "could not get announcements", error });
    }
  };
export const setCurrentPage = (currentPage) => {
  return {
    type: SET_CURRENTPAGE,
    payload: currentPage,
  };
};
export const setInnerCurrentPage = (currentPage) => {
  return {
    type: SET_INNER_CURRENTPAGE,
    payload: currentPage,
  };
};
export const setSearchTrue = () => {
  return {
    type: SET_SEARCH_TRUE,
  };
};
export const setDepart = (depart) => {
  return {
    type: SET_DEPART,
    payload: depart,
  };
};
export const setArrival = (arrival) => {
  return {
    type: SET_ARRIVAL,
    payload: arrival,
  };
};
export const deleteAnnouncement = (id) => async (dispatch) => {
  
  try {
    const res = await api.delete(
      `/api/announcement/deleteAnnouncement/${id}`
    );
    dispatch({
      type: DELETE_ANNOUNCEMENT,
      payload: id,
    });
  } catch (error) {
    console.log({ msg: "could not delete announcement", error });
  }
};
