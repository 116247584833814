import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { createAnnouncement } from "../redux/actions/announcementActions";
import {
  addCommission,
  addTotalCommission,
  removeCommission,
  removeTotalCommission,
  setCommission,
  setTotalCommission,
} from "../redux/actions/commissionActions";
import "./SendColis.css";
import { setAlert } from "../redux/actions/alertActions";

function SendColis() {
  const dispatch = useDispatch();
  const { commission, totalCommission } = useSelector(
    (state) => state.commissionReducer
  );
  const { auth } = useSelector((state) => state.authReducer);
  const token = localStorage.getItem("authTokens");
  const handleChange = (e) => {
    dispatch(setCommission(e.target.value));
    dispatch(setTotalCommission(commission));
  };
  // handle add one
  const handleAddOne = () => {
    dispatch(addCommission());
    dispatch(addTotalCommission());
  };
  // handle remove one
  const handleRemoveOne = () => {
    dispatch(removeCommission());
    dispatch(removeTotalCommission());
  };
  //handle set depart city
  const [depart, setDepart] = useState("");
  const handleDepart = (e) => {
    setDepart(e.target.value);
  };
  // handle set arrival city
  const [arrival, setArrival] = useState("");
  const handleArrival = (e) => {
    setArrival(e.target.value);
  };
  // handle set Date
  const [date, setDate] = useState("");
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  // handle set type of service
  const [typeService, setTypeService] = useState("");
  const handleTypeService = (e) => {
    setTypeService(e.target.value);
  };
  // handle set type of colis
  const [typeColis, setTypeColis] = useState("");
  const handleTypeColis = (e) => {
    setTypeColis(e.target.value);
  };
  // handle set approximate weight
  const [approximateWeight, setApproximateWeight] = useState("");
  const handleWeight = (e) => {
    setApproximateWeight(e.target.value);
  };
  // handle set file
  const [file, setFile] = useState(null);
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };
  // handle set dimension
  const [dimension, setDimension] = useState("");
  const handleDimension = (e) => {
    setDimension(e.target.value);
  };
  // handle set description
  const [description, setDescription] = useState("");
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  // handle set email
  const [email, setEmail] = useState("");
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  // handle set announcement
  const handleAnnouncement = (e) => {
    e.preventDefault();
    if (!(auth && token)) {
      dispatch(
        setAlert(
          "Vous n'êtes pas autorisé! Essayez à vous connecter d'abord!",
          "danger"
        )
      );
    } else if (!depart) {
      dispatch(setAlert("La ville de départ est requise!", "danger"));
    } else if (!arrival) {
      dispatch(setAlert("La ville d'arrivée est requise!", "danger"));
    } else if (!date) {
      dispatch(setAlert("La date du voyage est requise!"));
    } else if (!typeService) {
      dispatch(setAlert("Le type de service est requis!", "danger"));
    } else if (!typeColis) {
      dispatch(setAlert("Le type de colis est requis!", "danger"));
    } else if (!approximateWeight) {
      dispatch(setAlert("Le poids approximatif est requis!", "danger"));
    } else if (!dimension) {
      dispatch(setAlert("Votre email est requis!", "danger"));
    } else if (!commission) {
      dispatch(
        setAlert("La commission du voyage doit être supérieure à zero!")
      );
    } else {
      dispatch(
        createAnnouncement(
          depart,
          arrival,
          date,
          typeService,
          typeColis,
          approximateWeight,
          file,
          dimension,
          description,
          email,
          commission,
          totalCommission
        )
      );
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div>
        <div className="cell1">
          <div className="title">
            <h1>{t("EnvoyerColis.paragraph-acceuil")}</h1>
          </div>
          <div className="cell2">
            <div className="cell3">
              <input
                className="element"
                placeholder={t("EnvoyerColis.Ville de départ")}
                onChange={handleDepart}
              />
              <input
                className="element"
                placeholder={t("EnvoyerColis.Ville d'arrivée")}
                onChange={handleArrival}
              />
              <input
                className="element"
                type="date"
                placeholder={t("EnvoyerColis.Date")}
                onChange={handleDate}
              />
            </div>
            <div className="cell4">
              <label className="label1">
                {t("EnvoyerColis.Type de service")}
              </label>
              <div className="cell8">
                <div className="cell5">
                  <input
                    type="radio"
                    style={{ display: "none" }}
                    id="alert_form_option_0"
                    name="alert_form[option]"
                    required="required"
                    value="Je veux envoyer un colis"
                    onChange={handleTypeService}
                  />
                  <label className="label3" for="alert_form_option_0">
                    {t("EnvoyerColis.Je veux envoyer un colis")}
                  </label>
                </div>
                <div className="cell5">
                  <input
                    type="radio"
                    style={{ display: "none" }}
                    id="alert_form_option_1"
                    name="alert_form[option]"
                    required="required"
                    value="Je veux transporter un colis"
                    onChange={handleTypeService}
                  />
                  <label className="label3" for="alert_form_option_1">
                    {t("EnvoyerColis.Je veux transporter un colis")}
                  </label>
                </div>
                <div className="cell5">
                  <input
                    type="radio"
                    style={{ display: "none" }}
                    id="alert_form_option_2"
                    name="alert_form[option]"
                    required="required"
                    value="Je veux acheter et recevoir un colis"
                    onChange={handleTypeService}
                  />
                  <label className="label3" for="alert_form_option_2">
                    {t("EnvoyerColis.Je veux acheter et recevoir un colis")}
                  </label>
                </div>
              </div>
            </div>
            <div className="cell6">
              <div className="cell7">
                <select id="alert_form_product" onChange={handleTypeColis}>
                  <option value="" className="item1">
                    {t("EnvoyerColis.Type du colis")}
                  </option>
                  <option value="Documents" className="item1">
                    {t("EnvoyerColis.Documents")}
                  </option>
                  <option value="Vêtements" className="item1">
                    {t("EnvoyerColis.Vêtements")}
                  </option>
                  <option value="Clés" className="item1">
                    {t("EnvoyerColis.Clés")}
                  </option>
                  <option value="Livres" className="item1">
                    {t("EnvoyerColis.Livres")}
                  </option>
                  <option value="Bijoux" className="item1">
                    {t("EnvoyerColis.Bijoux")}
                  </option>
                  <option value="Produits électroniques" className="item1">
                    {t("EnvoyerColis.Produits électroniques")}
                  </option>
                  <option value="Autres" className="item1">
                    {t("EnvoyerColis.Autres")}
                  </option>
                </select>
              </div>
              <div className="cell7">
                <select id="alert_form_product" onChange={handleWeight}>
                  <option value="">
                    {t("EnvoyerColis.Poids approximatif")}
                  </option>
                  <option value="0g - 500g" className="item1">
                    0g - 500g
                  </option>
                  <option value="500g - 1Kg" className="item1">
                    500g - 1Kg
                  </option>
                  <option value="1Kg - 2Kg" className="item1">
                    1Kg - 2Kg
                  </option>
                  <option value="Plus de 2Kg" className="item1">
                    {t("EnvoyerColis.Plus de 2Kg")}
                  </option>
                </select>
              </div>
              <div className="cell7" id="cell7">
                <label
                  className="label2"
                  for="alert_form_attachments"
                  style={{ fontWeight: "500" }}
                >
                  <span>{t("EnvoyerColis.Ajouter photos, documents")}</span>
                </label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="alert_form_attachments"
                  name="alert_form[attachements]"
                  class="input-upload"
                  onChange={handleFile}
                />
              </div>
            </div>
            <div className="cell9">
              <label className="label4">{t("EnvoyerColis.Dimension")}</label>
              <div className="cell18">
                <div className="cell10">
                  <img
                    src="/lettre.png"
                    alt="colis"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div className="cell11">
                    <input
                      type="radio"
                      style={{ display: "none" }}
                      id="alert_form_dimension_0"
                      name="alert_form[dimension]"
                      required="required"
                      value="Petit"
                      onChange={handleDimension}
                    />
                    <label for="alert_form_dimension_0" className="label5">
                      {t("EnvoyerColis.Petit")}
                    </label>
                  </div>
                </div>
                <div className="cell10">
                  <img
                    src="/Chaussures.png"
                    alt="colis"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div className="cell11">
                    <input
                      type="radio"
                      style={{ display: "none" }}
                      id="alert_form_dimension_1"
                      name="alert_form[dimension]"
                      required="required"
                      value="Moyen"
                      onChange={handleDimension}
                    />
                    <label for="alert_form_dimension_1" className="label5">
                      {t("EnvoyerColis.Moyen")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="cell19">
                <div className="cell10">
                  <img
                    src="/valise.png"
                    alt="colis"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div className="cell11">
                    <input
                      type="radio"
                      style={{ display: "none" }}
                      id="alert_form_dimension_2"
                      name="alert_form[dimension]"
                      required="required"
                      value="Grand"
                      onChange={handleDimension}
                    />
                    <label for="alert_form_dimension_2" className="label5">
                      {t("EnvoyerColis.Grand")}
                    </label>
                  </div>
                </div>
                <div className="cell10">
                  <img
                    src="/table.png"
                    alt="colis"
                    style={{ height: "30px", width: "30px" }}
                  />
                  <div className="cell11">
                    <input
                      type="radio"
                      style={{ display: "none" }}
                      id="alert_form_dimension_3"
                      name="alert_form[dimension]"
                      required="required"
                      value="Très Grand"
                      onChange={handleDimension}
                    />
                    <label for="alert_form_dimension_3" className="label5">
                      {t("EnvoyerColis.Très grand")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <textarea
              id="alert_form_description"
              placeholder={t("EnvoyerColis.description")}
              onChange={handleDescription}
            ></textarea>
            <div className="cell12">
              <div className="cell13">
                <label className="label6">
                  {t("EnvoyerColis.Bonus voyageur")}
                </label>
                <div className="commission-box">
                  <button
                    className="minus-button"
                    onClick={handleRemoveOne}
                  ></button>
                  <div className="commission">
                    <input
                      type="number"
                      min={0}
                      id="alert_form_comission"
                      value={commission}
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    className="plus-button"
                    onClick={handleAddOne}
                  ></button>
                </div>
              </div>
              <div className="cell14">
                <div className="cell20">
                  <label className="label7">{t("EnvoyerColis.Total")}</label>
                  <div className="prix-total">
                    <input
                      id="alert_form_total"
                      type="text"
                      value={totalCommission}
                      disabled=""
                    ></input>
                    €
                  </div>
                </div>
                <span className="span2">
                  {t("EnvoyerColis.Avec prélèvement de commission")}
                </span>
              </div>
            </div>
            <div className="cell15">
              <div className="cell16">
                <input
                  type="email"
                  className="email"
                  id="alert_form_email"
                  name="alert_form[email]"
                  required="required"
                  placeholder={t("EnvoyerColis.Indiquez votre adresse email")}
                  onChange={handleEmail}
                />
              </div>
              <div className="cell17">
                <button className="submit" onClick={handleAnnouncement}>
                  {t("EnvoyerColis.Créer mon annonce")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SendColis;
