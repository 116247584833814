import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Error() {
  const alerts = useSelector((state) => state.alertReducer);
  const { t } = useTranslation();
  return (
    <div
      style={{ position: "sticky", top: "0px", width: "100%", zIndex: "1200" }}
    >
      {alerts?.map((alert) => (
        <Alert variant={alert.alertType} className="text-center" key={alert.id}>
          {t(`alerts.${alert.msg}`)}
        </Alert>
      ))}
    </div>
  );
}

export default Error;
