import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "./Blog.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function BlogDetails() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  let { id } = useParams();
  const { blogs } = useSelector((state) => state.blogReducer);
  let blog = blogs?.find((blog) => blog._id === id);
  // style facebook icons
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);

  const style = {
    normal: {
      fontSize: "18px",
      width: "40px",
      height: "40px",
      borderRadius: "100%",
      padding: "10px",
      cursor: "pointer",
      margin: "5px 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    facebook: {
      backgroundColor: "#3b5998",
      color: "#fff",
    },
    linkedin: {
      backgroundColor: "#0077b5",
      color: "#fff",
    },
    hover1: {
      backgroundColor: "#fff",
      color: "#3b5998",
      border: "2px solid #3b5998",
      fontSize: "20px",
    },
    hover2: {
      backgroundColor: "#fff",
      color: "#0077b5",
      border: "2px solid #0077b5",
      fontSize: "20px",
    },
  };
  return (
    <>
      <Navbar />
      {blog && (
        <div className="blog-card-details">
          <img
            src={`uploads/${blog.imageBlog}`}
            className="blog-card-image"
            alt="blog"
          />
          <div className="blog-card-body-description">
            <h3 className="blog-card-title-description">{blog.title}</h3>
            <p className="blog-card-description">{blog.description}</p>
            <p className="blog-date">{blog.createdAt.split("T")[0]}</p>
          </div>
          <ul className="icons-sahre">
            <li className="icon-facebook">
              <div
                class="fb-share-button"
                data-href="http://localhost:3000/63c01dee15187b6a95f271e5"
                data-layout="button_count"
                data-size="small"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A3000%2F&amp;src=sdkpreparse"
                  class="fb-xfbml-parse-ignore"
                  style={{
                    cursor: "default",
                    display: "block",
                    width: "40px",
                    borderRadius: "50%",
                    margin: "5px 10px",
                  }}
                >
                  <span
                    onMouseEnter={() => {
                      setHover1(true);
                    }}
                    onMouseLeave={() => {
                      setHover1(false);
                    }}
                    style={{
                      ...style.normal,
                      ...style.facebook,
                      ...(hover1 ? style.hover1 : null),
                    }}
                  >
                    <ImFacebook />
                  </span>
                </a>
              </div>
            </li>
            <li className="icon-linkedin">
              <span
                onMouseEnter={() => {
                  setHover2(true);
                }}
                onMouseLeave={() => {
                  setHover2(false);
                }}
                style={{
                  ...style.normal,
                  ...style.linkedin,
                  ...(hover2 ? style.hover2 : null),
                }}
              >
                <FaLinkedinIn />
              </span>
            </li>
            <li className="icon-instagram">
              <span className="instagram-share-button" style={style.normal}>
                {/* <FaInstagram /> */}
              </span>
            </li>
          </ul>
        </div>
      )}
      <Footer />
    </>
  );
}

export default BlogDetails;
