import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/authActions";
import { format } from "timeago.js";
import { setSeenNotifications } from "../redux/actions/notificationActions";
import "./Navbar.css";
import {
  setCurrentConversation,
  setCurrentUnseenChat,
} from "../redux/actions/conversationAction";
import {
  getUnseenConversations,
  removeSeenMessages,
} from "../redux/actions/messageActions";
import { useTranslation } from "react-i18next";
function Navbar() {
  const { auth, user } = useSelector((state) => state.authReducer);
  const { unseenLength, unseenNotifications } = useSelector(
    (state) => state.notificationReducer
  );
  const { unseenMessages, unseenConversations, unseenMessagesLength } =
    useSelector((state) => state.messageReducer);
  const { conversations } = useSelector((state) => state.conversationReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // logout
  const handleLogout = () => {
    dispatch(logout());
  };
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  // set seen notifications
  const handleSetSeenNotofications = () => {
    unseenNotifications.map((el) =>
      dispatch(setSeenNotifications(el._id, user._id))
    );
  };
  // handle navigate to contacts
  const handleNavigateContacts = () => {
    navigate("/Contacts");
  };
  // handle set current unseen chat & navigate to contacts
  const handleCurrentChat = (unseenConversationId) => {
    let unseenConversation = conversations?.find(
      (c) => c._id === unseenConversationId
    );
    if (unseenConversation) {
      dispatch(setCurrentConversation(unseenConversation, true));
      dispatch(removeSeenMessages(unseenConversationId));
      dispatch(getUnseenConversations());
    } else {
      dispatch(setCurrentUnseenChat(unseenConversationId));
      dispatch(removeSeenMessages(unseenConversationId));
      dispatch(getUnseenConversations());
    }
    navigate("/Contacts");
  };
  // handle language
  const [open, setOpen] = useState(false);
  const handleOpenSelectLanguage = () => {
    setOpen((open) => !open);
  };
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  useEffect(() => {
    for (
      let index = 0;
      index < document.getElementsByClassName("lang").length;
      index++
    ) {
      const element = document.getElementsByClassName("lang")[index];
      if (element.value === i18n.language) {
        element.setAttribute("selected", "true");
      }
    }
  }, []);

  return (
    <header>
      <div className="ng-scope">
        <nav id="mainNav" className="navbar-fixed-top navbar-default">
          <div className="navbar-header-min-width">
            <Link to="/" className="navbar-brand">
              <img src="Logo/logo5.png" alt="Logo ColisPO" />
            </Link>
            <div className="navbar-toggle-min-width">
              {auth && (
                <ul class="menu-toggle-main">
                  <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                      <i className="far fa-comments" />
                      {unseenMessagesLength > 0 && (
                        <span
                          className="badge badge-danger "
                          style={{
                            fontSize: ".7rem",
                            fontWeight: "500",
                            padding: "2px 4px",
                            position: "absolute",
                          }}
                        >{`${unseenMessagesLength}`}</span>
                      )}
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                      <span className="dropdown-item dropdown-header">{`${unseenMessagesLength} ${t(
                        "navbar.Nouveaux messages"
                      )}`}</span>
                      <div className="dropdown-divider" />
                      <div className="dropdown-divider" />
                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: unseenLength > 2 ? "scroll" : "hidden",
                        }}
                      >
                        {unseenConversations?.map((unseenConversation, i) => {
                          let unseenMessage = unseenMessages
                            ?.filter(
                              (message) =>
                                message.conversationId === unseenConversation
                            )
                            .pop();
                          return (
                            <div key={unseenConversation[i]}>
                              <div
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleCurrentChat(unseenConversation)
                                }
                              >
                                <div className="media">
                                  <img
                                    src={`uploads/${unseenMessage?.sender.imageUrl}`}
                                    alt="User Avatar"
                                    className="img-size-50 mr-3 img-circle"
                                  />
                                  <div className="media-body">
                                    <h3 className="dropdown-item-title">
                                      <strong>
                                        {unseenMessage?.sender.name}
                                      </strong>
                                      <span className="float-right text-sm text-danger">
                                        <i className="fas fa-star" />
                                      </span>
                                    </h3>
                                    <p className="text-sm">
                                      {unseenMessage?.text}
                                    </p>
                                    <p className="text-sm text-muted">
                                      <i className="far fa-clock mr-1" />
                                      {format(unseenMessage?.createdAt)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-divider" />
                            </div>
                          );
                        })}
                      </div>
                      <div className="dropdown-divider" />
                      {unseenMessagesLength > 0 && (
                        <div
                          href="#"
                          className="dropdown-item dropdown-footer"
                          onClick={handleNavigateContacts}
                          style={{ cursor: "pointer" }}
                        >
                          {t("navbar.Voir tous les messages")}
                        </div>
                      )}
                    </div>
                  </li>
                  {/* Notifications Dropdown Menu */}
                  <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                      <i className="far fa-bell" />
                      {unseenLength > 0 && (
                        <span
                          className="badge badge-warning"
                          style={{
                            fontSize: ".7rem",
                            fontWeight: "500",
                            padding: "2px 4px",
                            position: "absolute",
                          }}
                        >
                          {unseenLength}
                        </span>
                      )}
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                      <span className="dropdown-item dropdown-header">{`${unseenLength} ${t(
                        "navbar.Notifications"
                      )}`}</span>
                      <div className="dropdown-divider" />
                      <div className="dropdown-divider" />
                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: unseenLength > 2 ? "scroll" : "hidden",
                        }}
                      >
                        {unseenNotifications?.map((el) => (
                          <div key={el?._id}>
                            <div className="dropdown-item">
                              {/* Message Start */}
                              <div className="media">
                                <img
                                  src={`uploads/${el.sender.imageUrl}`}
                                  alt="User Avatar"
                                  className="img-size-50 mr-3 img-circle"
                                />
                                <div className="media-body">
                                  <h3 className="dropdown-item-title">
                                    <strong>{el.sender.name}</strong>
                                    <span className="float-right text-sm text-warning">
                                      <i className="fas fa-star" />
                                    </span>
                                  </h3>
                                  <p className="text-sm">
                                    {el?.type === 1
                                      ? `${el.sender.name} ${t(
                                          "navbar.notification.confirmer son annonce"
                                        )} ${el.announcementId?.count}`
                                      : el.type === 2
                                      ? `${el.sender.name} ${t(
                                          "navbar.notification.confirmer votre annonce"
                                        )} ${el.announcementId?.count}`
                                      : el.type === 3
                                      ? `${el.sender.name} ${t(
                                          "navbar.notification.confirmer réception pour son annonce"
                                        )} ${el.announcementId?.count}`
                                      : `${el.sender.name} ${t(
                                          "navbar.notification.confirmer réception pour votre annonce"
                                        )} ${el.announcementId?.count}`}
                                  </p>
                                  <p className="text-sm text-muted">
                                    <i className="far fa-clock mr-1" />
                                    {format(el.createdAt)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* Message End */}
                            <div className="dropdown-divider" />
                          </div>
                        ))}
                      </div>
                      <div className="dropdown-divider" />
                      {unseenLength > 0 && (
                        <div
                          className="dropdown-item dropdown-footer"
                          style={{ cursor: "pointer" }}
                          onClick={handleSetSeenNotofications}
                        >
                          {t("navbar.Marquer comme vue")}
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              )}
              <div
                className={
                  open ? "select-language-expanded" : "select-language"
                }
              >
                <select
                  class={open ? "select-arrow-active" : "select-selected"}
                  id={open ? "select-arrow" : null}
                  aria-label="Default select example"
                  onClick={handleOpenSelectLanguage}
                  onChange={handleChangeLanguage}
                >
                  <option
                    className="lang"
                    value="fr"
                    // style={{ backgroundColor: "red", color: "#fff" }}
                  >
                    FR
                  </option>
                  <option className="lang" value="en">
                    EN
                  </option>
                </select>
              </div>
              <button
                className="navbar-toggle"
                type="button"
                onClick={() => setIsNavExpanded(!isNavExpanded)}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
          </div>
          <div
            className={
              isNavExpanded
                ? "navigation-min-width expanded"
                : "navigation-min-width"
            }
          >
            <ul class="menu-area-main">
              <li>
                <Link to="/SendColis"> {t("navbar.Envoyer un colis")} </Link>
              </li>
              <li>
                <Link to="/Announcements">{t("navbar.Voir les annonces")}</Link>
              </li>
              {auth && (
                <li>
                  <Link to="/Contacts">{t("navbar.Contacts")}</Link>
                </li>
              )}
              <li>
                <Link to="/Blog">{t("navbar.Blog")}</Link>
              </li>
              <li></li>
              {auth ? (
                <>
                  <li>
                    <Link to="/profile" style={{ color: "#1f4063" }}>
                      <strong>{t("navbar.Profile")}</strong>
                    </Link>
                  </li>
                  <li onClick={handleLogout}>
                    <Link to="/login" style={{ color: "#1f4063" }}>
                      <strong>{t("navbar.Se déconnecter")}</strong>
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/login" style={{ color: "#1f4063" }}>
                      <strong>{t("navbar.Se connecter")}</strong>
                    </Link>
                  </li>
                  <li>
                    <Link to="/register" style={{ color: "#1f4063" }}>
                      <strong>{t("navbar.S'inscrire")}</strong>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
      <div className="ng-scope-max-width">
        <nav
          id="mainNav"
          className="navbar-fixed-top-max-width navbar-default-max-width"
        >
          <div className="navbar-header-max-width">
            <div className="navbar-toggle-max-width">
              <Link to="/" className="navbar-brand">
                <img src="Logo/logo5.png" alt="Logo ColisPO" />
              </Link>
              <ul class="menu-toggle-main">
                <li>
                  <Link to="/SendColis"> {t("navbar.Envoyer un colis")} </Link>
                </li>
                <li>
                  <Link to="/Announcements">
                    {t("navbar.Voir les annonces")}
                  </Link>
                </li>
                {auth && (
                  <li>
                    <Link to="/Contacts">Contacts</Link>
                  </li>
                )}
                <li>
                  <Link to="/Blog">{t("navbar.Blog")}</Link>
                </li>
                {auth && (
                  <li
                    className="nav-item dropdown"
                    style={{ paddingRight: "25px" }}
                  >
                    <a className="nav-link" data-toggle="dropdown" href="#">
                      <i className="far fa-comments" />
                      {unseenMessagesLength > 0 && (
                        <span
                          className="badge badge-danger "
                          style={{
                            fontSize: ".7rem",
                            fontWeight: "500",
                            padding: "2px 4px",
                            position: "absolute",
                          }}
                        >{`${unseenMessagesLength}`}</span>
                      )}
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                      <span className="dropdown-item dropdown-header">{`${unseenMessagesLength} ${t(
                        "navbar.Nouveaux messages"
                      )}`}</span>
                      <div className="dropdown-divider" />
                      <div className="dropdown-divider" />
                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: unseenLength > 2 ? "scroll" : "hidden",
                        }}
                      >
                        {unseenConversations?.map((unseenConversation, i) => {
                          let unseenMessage = unseenMessages
                            ?.filter(
                              (message) =>
                                message.conversationId === unseenConversation
                            )
                            .pop();
                          return (
                            <div key={unseenConversation[i]}>
                              <div
                                className="dropdown-item"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleCurrentChat(unseenConversation)
                                }
                              >
                                <div className="media">
                                  <img
                                    src={`uploads/${unseenMessage?.sender.imageUrl}`}
                                    alt="User Avatar"
                                    className="img-size-50 mr-3 img-circle"
                                  />
                                  <div className="media-body">
                                    <h3 className="dropdown-item-title">
                                      <strong>
                                        {unseenMessage?.sender.name}
                                      </strong>
                                      <span className="float-right text-sm text-danger">
                                        <i className="fas fa-star" />
                                      </span>
                                    </h3>
                                    <p className="text-sm">
                                      {unseenMessage?.text}
                                    </p>
                                    <p className="text-sm text-muted">
                                      <i className="far fa-clock mr-1" />
                                      {format(unseenMessage?.createdAt)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-divider" />
                            </div>
                          );
                        })}
                      </div>
                      <div className="dropdown-divider" />
                      {unseenMessagesLength > 0 && (
                        <div
                          href="#"
                          className="dropdown-item dropdown-footer"
                          onClick={handleNavigateContacts}
                          style={{ cursor: "pointer" }}
                        >
                          {t("navbar.Voir tous les messages")}
                        </div>
                      )}
                    </div>
                  </li>
                )}
                {auth && (
                  <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                      <i className="far fa-bell" />
                      {unseenLength > 0 && (
                        <span
                          className="badge badge-warning"
                          style={{
                            fontSize: ".7rem",
                            fontWeight: "500",
                            padding: "2px 4px",
                            position: "absolute",
                          }}
                        >
                          {unseenLength}
                        </span>
                      )}
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                      <span className="dropdown-item dropdown-header">{`${unseenLength} ${t(
                        "navbar.Notifications"
                      )}`}</span>
                      <div className="dropdown-divider" />
                      <div className="dropdown-divider" />
                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: unseenLength > 2 ? "scroll" : "hidden",
                        }}
                      >
                        {unseenNotifications?.map((el) => (
                          <div key={el._id}>
                            <div className="dropdown-item">
                              {/* Message Start */}
                              <div className="media">
                                <img
                                  src={`uploads/${el.sender.imageUrl}`}
                                  alt="User Avatar"
                                  className="img-size-50 mr-3 img-circle"
                                />
                                <div className="media-body">
                                  <h3 className="dropdown-item-title">
                                    <strong>{el.sender.name}</strong>
                                    <span className="float-right text-sm text-warning">
                                      <i className="fas fa-star" />
                                    </span>
                                  </h3>
                                  <p className="text-sm">
                                    {el?.type === 1
                                      ? `${el.sender.name} ${t(
                                          "navbar.notification.confirmer son annonce"
                                        )} ${el.announcementId?.count}`
                                      : el.type === 2
                                      ? `${el.sender.name} ${t(
                                          "navbar.notification.confirmer votre annonce"
                                        )} ${el.announcementId?.count}`
                                      : el.type === 3
                                      ? `${el.sender.name} ${t(
                                          "navbar.notification.confirmer réception pour son annonce"
                                        )} ${el.announcementId?.count}`
                                      : `${el.sender.name} ${t(
                                          "navbar.notification.confirmer réception pour votre annonce"
                                        )} ${el.announcementId?.count}`}
                                  </p>
                                  <p className="text-sm text-muted">
                                    <i className="far fa-clock mr-1" />
                                    {format(el.createdAt)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* Message End */}
                            <div className="dropdown-divider" />
                          </div>
                        ))}
                      </div>
                      <div className="dropdown-divider" />
                      {unseenLength > 0 && (
                        <div
                          className="dropdown-item dropdown-footer"
                          style={{ cursor: "pointer" }}
                          onClick={handleSetSeenNotofications}
                        >
                          {t("navbar.Marquer comme vue")}
                        </div>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div className="navbar-max-width">
              <ul className="menu-main-max-width">
                {auth ? (
                  <>
                    <li>
                      <Link to="/profile">{t("navbar.Profile")}</Link>
                    </li>
                    <li onClick={handleLogout}>
                      <Link to="/login">{t("navbar.Se déconnecter")}</Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">{t("navbar.Se connecter")}</Link>
                    </li>
                    <li>
                      <Link to="/register">{t("navbar.S'inscrire")}</Link>
                    </li>
                  </>
                )}
                <li style={{ paddingTop: "6px" }}>
                  <div
                    className={
                      open ? "select-language-expanded" : "select-language"
                    }
                  >
                    <select
                      class={open ? "select-arrow-active" : "select-selected"}
                      id={open ? "select-arrow" : null}
                      aria-label="Default select example"
                      onClick={handleOpenSelectLanguage}
                      onChange={handleChangeLanguage}
                    >
                      <option className="lang" value="fr">
                        FR
                      </option>
                      <option className="lang" value="en">
                        EN
                      </option>
                    </select>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
