import { CURRENT, FAIL, LOGIN, LOGOUT, REGISTER } from "../types/authTypes";

const initialState = {
  auth: false,
  user: null,
  loading: true,
};
// Check for auth tokens in local storage
const authTokens = JSON.parse(localStorage.getItem("authTokens"));

if (authTokens) {
  initialState.auth = true;
  initialState.loading = false;
  
}
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REGISTER:
    case LOGIN:
      localStorage.setItem("authTokens", JSON.stringify( payload.authTokens));
      return {
        ...state,
        auth: true,
        user: payload.user,
        loading: false,
      };
    case CURRENT:
      return {
        ...state,
        auth: true,
        loading: false,
        user: payload,
      };
    case FAIL:
    case LOGOUT:
      localStorage.removeItem("authTokens");
      return {
        ...state,
        auth: false,
        user: null,
        loading: false,
      };

    default:
      return state;
  }
};
export default authReducer;
