import useaxios from "../../utils/customAxios";
import { DELETE_BLOG, GET_BLOGS } from "../types/blogTypes";
import { setAlert } from "./alertActions";
const api=useaxios()
export const getBlogs = () => async (dispatch) => {
  try {
    const res = await api.get(`/api/blog/getBlogs`);
    dispatch({
      type: GET_BLOGS,
      payload: res.data.blogs,
    });
  } catch (error) {
    console.log({ msg: "could not get blogs", error });
  }
};

export const createBlog = (data, Photo) => async (dispatch) => {
  const info = new FormData();
  info.append("title", data.title);
  info.append("description", data.description);
  info.append("myBlogImage", Photo);
  
  try {
    const res = await api.post(`${process.env.REACT_APP_API_URL}/api/blog/createBlog`, info);
    dispatch(getBlogs());
  } catch (error) {
    error.response.data.errors.forEach((err) =>
      dispatch(setAlert(err.msg, "danger"))
    );
    console.log({ msg: "could not post blog", error });
  }
};
export const deleteBlog = (id) => async (dispatch) => {
  
  try {
    const res = await api.delete(`${process.env.REACT_APP_API_URL}/api/blog/deleteBlog/${id}`);
    dispatch({
      type: DELETE_BLOG,
      payload: id,
    });
  } catch (error) {
    console.log({ msg: "could not delete blog", error });
  }
};
