import useaxios from "../../utils/customAxios";
import { GET_NOTIFICATIONS, GET_UNSEEN_NOTIFICATIONS, SET_NEW_NOTIFICATION, SET_SOCKET } from "../types/notificationTypes"
const api=useaxios()
// set socket
export const setSocket=(socket)=>{
    return{
        type:SET_SOCKET,
        payload:socket,
    }
}
// post notification
export const setNotification=(notification)=>async(dispatch)=>{
    
    try {
        const res=await api.post('/api/notification/createNotification', notification)
    } catch (error) {
        console.log({msg:'could not set notification', error})
    }
}
// get notifications of a user
export const getNotifications=(userId)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/notification/${userId}`)
        dispatch({
            type:GET_NOTIFICATIONS,
            payload:res.data.notifications
        })
    } catch (error) {
        console.log({msg:'could not get notifications', error})
    }
}
// set arrival notification
export const getArrivalNotification=(notification)=>{
    return{
        type:SET_NEW_NOTIFICATION,
        payload:notification
    }
}
// get unseen notifications
export const getUnseenNotifications=(userId)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/notification/unseenNotification/${userId}`)
        dispatch({
            type:GET_UNSEEN_NOTIFICATIONS,
            payload:res.data.unseenNotifications
        })
    } catch (error) {
        console.log({msg:'could not get unseen notifications', error})
    }
}
// set seen notifications
export const setSeenNotifications=(notificationId,userId)=>async(dispatch)=>{
    
    try {
        const res=await api.put(`/api/notification/seenNotifications/${notificationId}`, {notificationId})
        dispatch(getUnseenNotifications(userId))
    } catch (error) {
        console.log({msg:'could not set seen notifications', error})
    }
}