import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import "./Blog.css";
import { AiFillDelete } from "react-icons/ai";
import { deleteBlog } from "../redux/actions/blogActions";
import { useTranslation } from "react-i18next";

function Blog() {
  const { user } = useSelector((state) => state.authReducer);
  const { blogs } = useSelector((state) => state.blogReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div className="blog">
        {blogs?.map((blog) => (
          <div className="blog-card" key={blog._id}>
            <img
              src={`uploads/${blog.imageBlog}`}
              className="blog-card-image"
              alt="blog"
            />
            <div className="blog-card-body">
              <h2 className="card-blog-title">ColisPO</h2>
              <h3 className="blog-card-title">{blog.title}</h3>
              <p className="blog-date">{blog.createdAt.split("T")[0]}</p>
              <Link to={`/${blog._id}`}>
                <button className="blog-details">
                  {t("Blog.Plus de Détails")}
                </button>
              </Link>
            </div>
            {user?.role === "admin" && (
              <button
                className="fillDeleteBlog"
                onClick={() => dispatch(deleteBlog(blog._id))}
              >
                <AiFillDelete />
              </button>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Blog;
