import useaxios from "../../utils/customAxios";
import { GET_ADMIN_COMMANDE, GET_COMMANDE, SET_COMMANDE_CURRENTPAGE, SET_COMMANDE_LOADING } from "../types/commandeTypes"
import { setAlert } from "./alertActions"
const api=useaxios()
export const setCommande=(announceOwner,announceContacter,announcementId,conversationId)=>async(dispatch)=>{
    
    try {
        const res=await api.post('/api/commande', {announceOwner,announceContacter,announcementId,conversationId})
    } catch (error) {
        console.log({msg:'could not set commande', error})
    }
}
export const confirmCommande=(announceOwner,announceContacter,announcementId,conversationId)=>async(dispatch)=>{
    
    try {
        const res=await api.put('/api/commande', {announceOwner,announceContacter,announcementId,conversationId})
    } catch (error) {
        error.response.data.errors.forEach(err=>dispatch(setAlert(err.msg,"danger")))
        console.log({msg:'could not confirm commande', error})
    }
}
// get commandes of a user
export const getCommandes=(id,currentPage)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/commande/${id}/${currentPage}`)
        dispatch({
            type:GET_COMMANDE,
            payload:res.data
        })
    } catch (error) {
        console.log({msg:'could not get commandes', error})
    } 
}
// get all commandes
export const getAdminCommandes=(currentPage)=>async(dispatch)=>{
    
    try {
        const res=await api.post(`/api/commande/adminCommandes/${currentPage}`, {currentPage})
        dispatch({
            type:GET_ADMIN_COMMANDE,
            payload:res.data
        })
    } catch (error) {
        console.log({msg:'could not get commandes', error})
    } 
}
// set loading true
export const setCommandeLoading=()=>{
    return{
        type:SET_COMMANDE_LOADING,
    }
}
// set current page
export const setCommandeCurrentPage=(currentPage)=>{
    return{
        type:SET_COMMANDE_CURRENTPAGE,
        payload:currentPage
    }
}