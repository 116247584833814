import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

function NotFound() {
  return (
    <>
    <Navbar />
    <div style={{ textAlign: "center" }}>
      <img
        src='https://aeroclub-issoire.fr/wp-content/uploads/2020/05/image-not-found-300x225.jpg'
        alt='not found'
        width='70%'
        height='650px'
      />
    </div>
    <Footer />
    </>
  );
}

export default NotFound;
