import {
  DELETE_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  GET_INNER_ANNOUNCEMENTS,
  SET_ARRIVAL,
  SET_CURRENTPAGE,
  SET_DEPART,
  SET_INNER_CURRENTPAGE,
  SET_INNER_LOADING,
  SET_LOADING,
  SET_SEARCH_TRUE,
} from "../types/announcementTypes";

const initialState = {
  announcements: null,
  innerAnnouncements: null,
  firstPage: 1,
  firstInnerPage: 1,
  currentPage: 0,
  currentInnerPage: 0,
  total: 1,
  innerTotal: 1,
  loading: true,
  innerLoading: true,
  search: false,
  depart1: "",
  arrival1: "",
};

const announcementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: payload.currentAnnouncements,
        total: Math.ceil(payload.length / 10),
        loading: false,
      };
    case GET_INNER_ANNOUNCEMENTS:
      return {
        ...state,
        innerAnnouncements: payload.myAnnouncements,
        innerTotal: Math.ceil(payload.length / 10),
        innerLoading: false,
      };
    case SET_LOADING:
      return { ...state, loading: true };
    case SET_INNER_LOADING:
      return { ...state, innerLoading: true };
    case SET_CURRENTPAGE:
      return { ...state, currentPage: payload };
    case SET_INNER_CURRENTPAGE:
      return { ...state, currentInnerPage: payload };
    case SET_SEARCH_TRUE:
      return { ...state, search: true };
    case SET_DEPART:
      return { ...state, depart1: payload };
    case SET_ARRIVAL:
      return { ...state, arrival1: payload };
    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.filter(
          (announcement) => announcement._id !== payload
        ),
      };
    default:
      return state;
  }
};
export default announcementReducer;
