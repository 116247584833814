import axios from "axios";
import React from "react";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";



const useaxios = () => {

  const authTokens=localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null
  const axiosInstance = axios.create({
    baseURL:"https://api.colispo.com",
    headers: {
      Authorization: `${authTokens?.access}`,
    },
  });

  axiosInstance.interceptors.request.use(
    async (req) => {
      if(authTokens){const user = jwtDecode(authTokens?.access);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;
      const response = await axios.post(`https://api.colispo.com/api/auth/refresh-token/`, {
        refresh: authTokens.refresh,
      });
      localStorage.setItem("authTokens", JSON.stringify(response?.data));
      req.headers.Authorization = `${response?.data?.access}`;
      return req;}
      else{
        window.location.href = '/login';
      }
    },
    (error) => {
      console.log("error");
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error);
    }
  );

  return axiosInstance;
};
export default useaxios;
