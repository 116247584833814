import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  deleteSliderImage,
  editSliderImage,
} from "../../redux/actions/sliderActions";
function ConfigOneSlider({ img, indice }) {
  const dispatch = useDispatch();
  // handle edit slider
  const [editSlider, setEditSlider] = useState(false);
  const [newSliderName, setNewSliderName] = useState("");
  const handleEditSlider = () => {
    setEditSlider(!editSlider);
    setNewSliderName("");
  };
  const handleChangeNameSlider = (e) => {
    setNewSliderName(e.target.value);
  };
  const { t } = useTranslation();

  return (
    <div className="slider-container" key={indice}>
      <img src={`slider/${img}`} alt={`slider${indice}`} />
      <p className="slider-tag">{img.split(".")[0]}</p>
      <div className="slider-options">
        {editSlider && (
          <>
            <input
              className="slider-edit-name"
              placeholder={t("dashboard.nouveau nom du slider")}
              value={newSliderName}
              onChange={handleChangeNameSlider}
            ></input>
            <button
              onClick={() => dispatch(editSliderImage(img, newSliderName))}
              className="edit-slider"
            >
              {t("dashboard.éditer")}
            </button>
          </>
        )}
        <button className="fillEditSlider" onClick={handleEditSlider}>
          <AiFillEdit />
        </button>
        <button
          className="fillDeleteSlider"
          onClick={() => dispatch(deleteSliderImage(img))}
        >
          <AiFillDelete />
        </button>
      </div>
    </div>
  );
}

export default ConfigOneSlider;
