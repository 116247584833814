import {
  GET_ADMIN_COMMANDE,
  GET_COMMANDE,
  SET_COMMANDE_CURRENTPAGE,
  SET_COMMANDE_LOADING,
} from "../types/commandeTypes";
const {
  GET_CONVERSATIONS,
  SET_CURRENT_CONVERSATION,
} = require("../types/conversationTypes");

const initialState = {
  conversations: null,
  currentConversation: null,
  commandes: null,
  firstPage: 1,
  currentPage: 0,
  total: 1,
  loading: true,
};

const conversationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONVERSATIONS:
      return { ...state, conversations: payload.conversation };
    case SET_CURRENT_CONVERSATION:
      return { ...state, currentConversation: payload };
    case GET_COMMANDE:
      return {
        ...state,
        commandes: payload.currentCommandes,
        total: Math.ceil(payload.length / 10),
        loading: false,
      };
    case GET_ADMIN_COMMANDE:
      return {
        ...state,
        commandes: payload.currentCommandes,
        adminTotal: Math.ceil(payload.length / 10),
        loading: false,
      };
    case SET_COMMANDE_LOADING:
      return { ...state, loading: true };
    case SET_COMMANDE_CURRENTPAGE:
      return { ...state, currentPage: payload };
    default:
      return state;
  }
};

export default conversationReducer;
