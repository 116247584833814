import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import "./Paiement.css";

function paiement() {
  return (
    <>
      <Navbar />
      <div>
        <div className="cell1">
          <div className="title1">
            <h1>
              Veuillez se procéder au paiement pour pouvoir communiquer avec
              l'annonceur de la publication.
            </h1>
          </div>
          <div className="banner">
            <div className="inner-banner">
              <div className="banner-detail">
                <h1 className="banner-title">Conditions de paiements</h1>
                <div className="bread-crumb">
                  <ul className="bread">
                    <i className="fa fa-home">
                      <Link to="/">Accueil</Link>
                    </i>
                    <i className="fa fa-credit-card">
                      <a> Conditions de paiements</a>
                    </i>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="Banner1">
            <div className="Banner2">
              <div className="title2">
                <h2>Conditions de paiements</h2>
              </div>
              <div className="mb-20">
                <h3>
                  <i className="fa fa-dot-circle-o"></i>
                  <stong>Supervol</stong> vous propose un paiement sécurisé par
                  carte de crédit (CIB, Visa, Mastercard, etc)
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default paiement;
