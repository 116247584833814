import React, { useEffect, useRef, useState } from "react";
import "./Contact.css";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Conversation from "../Components/conversations/Conversation";
import Message from "../Components/messages/Message";
import Commande from "../Components/commande/Commande";
import { useDispatch, useSelector } from "react-redux";
import {
  getConversations,
  setCurrentConversation,
  setCurrentSeenChat,
} from "../redux/actions/conversationAction";
import {
  getArrivalMessage,
  getMessages,
  getOtherUser,
  getUnseenConversations,
  removeSeenMessages,
  setMessage,
} from "../redux/actions/messageActions";
import { setAlert } from "../redux/actions/alertActions";
import { useTranslation } from "react-i18next";

function Contact() {
  const dispatch = useDispatch();
  const { conversations, currentConversation } = useSelector(
    (state) => state.conversationReducer
  );
  const { messages, unseenMessages } = useSelector(
    (state) => state.messageReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const [newMessage, setNewMessage] = useState("");
  const scrollRef = useRef();
  //const socket=useRef()
  const chatMessages = document.querySelector(".chatBoxTop");
  // const message=messages?.find(message=>message.sender !== user._id)
  const { socket } = useSelector((state) => state.notificationReducer);
  const receiverId = currentConversation?.members.find(
    (member) => member !== user?._id
  );
  const { otherUser } = useSelector((state) => state.messageReducer);
  // get other user chatting with currentUser
  useEffect(() => {
    currentConversation && dispatch(getOtherUser(receiverId));
  }, [currentConversation]);
  // get other user chatting with message
  /* useEffect(() => {
      message&&dispatch(getOtherUser(message.sender))
    }, [message]) */
  // scroll down every conversation
  useEffect(() => {
    setTimeout(
      () =>
        chatMessages && (chatMessages.scrollTop = chatMessages.scrollHeight),
      500
    );
  }, [currentConversation, chatMessages, messages]);
  // get conversations
  useEffect(() => {
    dispatch(getConversations(user._id));
    window.scrollTo(0, 0);
  }, [user._id, currentConversation]);
  // handle set current chat
  const handleSetCurrentConversataion = (current) => {
    let unseenMessage = unseenMessages?.find(
      (m) => m.conversationId === current._id
    );
    if (unseenMessage) {
      dispatch(setCurrentConversation(current, true));
      dispatch(removeSeenMessages(current._id));
      dispatch(getUnseenConversations());
    } else {
      dispatch(setCurrentSeenChat(current));
    }
  };
  // get messages of a conversation
  useEffect(() => {
    currentConversation && dispatch(getMessages(currentConversation?._id));
  }, [currentConversation]);
  // handle submit message
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage !== "") {
      const message = {
        conversationId: currentConversation?._id,
        sender: user?._id,
        receiver: receiverId,
        text: newMessage,
        model_type:
          user?.status === "Particular" ? "UserParticular" : "UserProfessional",
        seen: false,
      };
      dispatch(setMessage(message));
      socket?.current.emit("sendMessage", {
        conversationId: currentConversation?._id,
        sender: user?._id,
        senderName: user?.name,
        senderImageUrl: user?.imageUrl,
        receiver: receiverId,
        text: newMessage,
        model_type:
          user?.status === "Particular" ? "UserParticular" : "UserProfessional",
        seen: false,
      });
      setNewMessage("");
      setTimeout(
        () => (chatMessages.scrollTop = chatMessages.scrollHeight),
        500
      );
      let unseenMessage = unseenMessages?.find(
        (m) => m.conversationId === currentConversation._id
      );
      if (unseenMessage) {
        dispatch(setCurrentConversation(currentConversation, false));
        dispatch(removeSeenMessages(currentConversation._id));
        dispatch(getUnseenConversations());
      }
    }
  };
  // scroll messages
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  //
  const { notifications } = useSelector((state) => state.notificationReducer);
  /* useEffect(() => {
        console.log(notifications)
      }, [notifications]) */
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      {currentConversation && (
        <div className="step-wizard">
          <ul className="step-wizard-list">
            <li className="step-wizard-item">
              <span className="progress-count">1</span>
              <span className="progress-label">Proposition Acceptée</span>
            </li>
            <li className="step-wizard-item current-item">
              <span className="progress-count">2</span>
              <span className="progress-label">Colis Envoyé</span>
            </li>
            <li className="step-wizard-item">
              <span className="progress-count">3</span>
              <span className="progress-label">Colis Reçu</span>
            </li>
            <li className="step-wizard-item">
              <span className="progress-count">4</span>
              <span className="progress-label">Paiement</span>
            </li>
          </ul>
        </div>
      )}
      <div className="messenger">
        <div className="chatMenu">
          <div className="chatMenuWrapper">
            <input
              placeholder={t("Contacts.Rechercher des utilisateurs")}
              className="chatMenuInput"
            ></input>
            {conversations &&
              conversations.map((c) => (
                <div
                  key={c._id}
                  onClick={() => handleSetCurrentConversataion(c)}
                >
                  <Conversation conversation={c} currentUser={user} />
                </div>
              ))}
          </div>
        </div>
        <div className="chatBox">
          <div className="chatBoxWrapper">
            {currentConversation ? (
              <>
                <div className="chatBoxTop">
                  {messages?.map((m) => (
                    <div key={m._id}>
                      <Message message={m} own={m.sender === user._id} />
                    </div>
                  ))}
                </div>
                <div className="chatBoxBottom">
                  <textarea
                    className="chatMessageInput"
                    placeholder={t("Contacts.écris quelque chose")}
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                    onKeyPress={(e) => {
                      e.key === "Enter" && handleSubmit(e);
                    }}
                  ></textarea>
                  <button className="chatSubmitButton" onClick={handleSubmit}>
                    {t("Contacts.envoyer")}
                  </button>
                </div>
              </>
            ) : (
              <span className="noConversationText">
                {t("Contacts.Ouvrir une conversation")}
              </span>
            )}
          </div>
        </div>
        <div className="commande">
          <div className="commandeWrapper">
            {currentConversation && <Commande />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
