import useaxios from "../../utils/customAxios";
import { getCurrentUser } from "./authActions"
const api=useaxios()
// update phone number particular
export const editPhoneParticular=(phone)=>async(dispatch)=>{
    
    try {
        await api.put('/api/profile/updatePhoneParticular', {phone})
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update phone number", error})
    }
}
// update phone professional
export const editPhoneProfessional=(phone)=>async(dispatch)=>{
    
    try {
        await api.put('/api/profile/updatePhoneProfessional', {phone})
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update phone number", error})
    }
}
// update email particular
export const editEmailParticular=(email)=>async(dispatch)=>{
    
    try {
        await api.put('/api/profile/updateEmailParticular', {email})
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update email adress", error})
    }
}
// update email professional
export const editEmailProfessional=(email)=>async(dispatch)=>{
    
    try {
        await api.put('/api/profile/updateEmailProfessional', {email})
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update email adress", error})
    }
}
// update password particular
export const editPasswordParticular=(password)=>async(dispatch)=>{
    
    try {
        await api.put('/api/profile/updatePasswordParticular', {password})
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update password", error})        
    }
}
// update password professional
export const editPasswordProfessional=(password)=>async(dispatch)=>{
    
    try {
        await api.put('/api/profile/updatePasswordProfessional', {password})
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update password", error})        
    }
}
// update photo particular
export const editPhotoParticular=(file)=>async(dispatch)=>{
    const data=new FormData()
    data.append('myImage',file)
    
    try {
        await api.put('/api/profile/updatePhotoParticular', data)
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update profile photo", error})
    }
}
// update photo professional
export const editPhotoProfessional=(file)=>async(dispatch)=>{
    const data=new FormData();
    data.append('myImage',file);
    
    try {
        await api.put('/api/profile/updatePhotoProfessional', data)
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update profile photo", error})
    }
}
// update passeport photo
export const editPassportParticular=(file)=>async(dispatch)=>{
    const data=new FormData();
    data.append('myImage',file);
    
    try {
        await api.put('/api/profile/updatePasseportParticular', data)
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update passeport photo", error})
    }
}
// update patent photo
export const editPatentPhoto=(file)=>async(dispatch)=>{
    const data=new FormData();
    data.append('myImage',file);
    
    try {
        await api.put('/api/profile/updatePatentPhoto', data)
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update patent photo", error})
    }
}
// update register phtoto
export const editCommercialRegister=(file)=>async(dispatch)=>{
    const data=new FormData();
    data.append('myImage',file);
    
    try {
        await api.put('/api/profile/updateCommercialRegister', data)
        dispatch(getCurrentUser())
    } catch (error) {
        console.log({msg:"could not update commercial register photo", error})
    }
}
// get one user
export const getUser=(id,setUser)=>async(dispatch)=>{
    
    try {
        const res=await api.get(`/api/profile/${id}`)
        setUser(res.data.user)
    } catch (error) {
        console.log({msg:"could not get user", error})
    }
}