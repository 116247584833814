import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminCommandes,
  getCommandes,
  setCommandeCurrentPage,
  setCommandeLoading,
} from "../../redux/actions/commandeActions";
import Load from "../../Components/Load";
import { Table } from "react-bootstrap";
import "./MesCommandes.css";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { setSeenNotifications } from "../../redux/actions/notificationActions";
import { format } from "timeago.js";
import "./Dashboard.css";
import {
  setCurrentConversation,
  setCurrentUnseenChat,
} from "../../redux/actions/conversationAction";
import {
  getUnseenConversations,
  removeSeenMessages,
} from "../../redux/actions/messageActions";
import { useTranslation } from "react-i18next";
function MesCommandes() {
  const { auth, user } = useSelector((state) => state.authReducer);
  const { unseenLength, unseenNotifications } = useSelector(
    (state) => state.notificationReducer
  );
  const { unseenMessages, unseenConversations, unseenMessagesLength } =
    useSelector((state) => state.messageReducer);
  const { conversations } = useSelector((state) => state.conversationReducer);
  // handle navigate to contacts
  const handleNavigateContacts = () => {
    navigate("/Contacts");
  };
  // handle set current unseen chat & navigate to contacts
  const handleCurrentChat = (unseenConversationId) => {
    let unseenConversation = conversations?.find(
      (c) => c._id === unseenConversationId
    );
    if (unseenConversation) {
      dispatch(setCurrentConversation(unseenConversation, true));
      dispatch(removeSeenMessages(unseenConversationId));
      dispatch(getUnseenConversations());
    } else {
      dispatch(setCurrentUnseenChat(unseenConversationId));
      dispatch(removeSeenMessages(unseenConversationId));
      dispatch(getUnseenConversations());
    }
    navigate("/Contacts");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // set seen notifications
  const handleSetSeenNotofications = () => {
    unseenNotifications.map((el) =>
      dispatch(setSeenNotifications(el._id, user._id))
    );
  };
  const { firstPage, currentPage, total, loading, commandes } = useSelector(
    (state) => state.conversationReducer
  );
  useEffect(() => {
    dispatch(setCommandeLoading());
    user &&
      user.role === "user" &&
      dispatch(getCommandes(user && user._id, firstPage));
    user && user.role === "admin" && dispatch(getAdminCommandes(firstPage));
    dispatch(setCommandeCurrentPage(0));
  }, [user]);
  // handle page change
  const handlePageClick = (data) => {
    dispatch(setCommandeLoading());
    user &&
      user.role === "user" &&
      dispatch(getCommandes(user && user._id, data.selected + 1));
    user &&
      user.role === "admin" &&
      dispatch(getAdminCommandes(data.selected + 1));
    dispatch(setCommandeCurrentPage(data.selected + 1));
    window.scrollTo(0, 0);
  };
  // handle navigate to profil
  const handleNavigateProfil = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/profile");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/profile"), 300);
    }
  };
  // handle navigate to commandes
  const handleNavigateCommandes = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/mesCommandes");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/mesCommandes"), 300);
    }
  };
  // handle navigate to edit profile
  const handleNavigateEditProfile = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/editProfile");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/editProfile"), 300);
    }
  };
  // handle navigate to annonces
  const handleNavigateAnnonces = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/mesAnnonces");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/mesAnnonces"), 300);
    }
  };
  // handle navigate to creation blog
  const handleNavigateCreationBlog = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/creationBlog");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/creationBlog"), 300);
    }
  };
  const handleNavigateConfigSlider = () => {
    var w = window.innerWidth;
    if (w > 992) {
      navigate("/configSlider");
    } else {
      document.querySelector("body").className =
        "sidebar-closed sidebar-collapse";
      setTimeout(() => navigate("/configSlider"), 300);
    }
  };
  // handle language
  const [open, setOpen] = useState(false);
  const handleOpenSelectLanguage = () => {
    setOpen((open) => !open);
  };
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  useEffect(() => {
    for (
      let index = 0;
      index < document.getElementsByClassName("lang").length;
      index++
    ) {
      const element = document.getElementsByClassName("lang")[index];
      if (element.value === i18n.language) {
        element.setAttribute("selected", "true");
      }
    }
  }, []);
  return (
    <div className="wrapper">
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-sm-inline-block">
            <Link to="/" className="nav-link">
              {t("dashboard.accueil")}
            </Link>
          </li>
          <li className="nav-item d-sm-inline-block">
            <Link to="/profile" className="nav-link">
              {t("dashboard.profil")}
            </Link>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {auth && (
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-comments" />
                {unseenMessagesLength > 0 && (
                  <span
                    className="badge badge-danger "
                    style={{
                      fontSize: ".7rem",
                      fontWeight: "500",
                      padding: "2px 4px",
                      position: "absolute",
                    }}
                  >{`${unseenMessagesLength}`}</span>
                )}
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">{`${unseenMessagesLength} ${t(
                  "navbar.Nouveaux messages"
                )}`}</span>
                <div className="dropdown-divider" />
                <div className="dropdown-divider" />
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: unseenLength > 2 ? "scroll" : "hidden",
                  }}
                >
                  {unseenConversations?.map((unseenConversation, i) => {
                    let unseenMessage = unseenMessages
                      ?.filter(
                        (message) =>
                          message.conversationId === unseenConversation
                      )
                      .pop();
                    return (
                      <div key={unseenConversation[i]}>
                        <div
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleCurrentChat(unseenConversation)}
                        >
                          <div className="media">
                            <img
                              src={`uploads/${unseenMessage?.sender.imageUrl}`}
                              alt={t("dashboard.avatar d'utilisateur")}
                              className="img-size-50 mr-3 img-circle"
                            />
                            <div className="media-body">
                              <h3 className="dropdown-item-title">
                                <strong>{unseenMessage?.sender.name}</strong>
                                <span className="float-right text-sm text-danger">
                                  <i className="fas fa-star" />
                                </span>
                              </h3>
                              <p className="text-sm">{unseenMessage?.text}</p>
                              <p className="text-sm text-muted">
                                <i className="far fa-clock mr-1" />
                                {format(unseenMessage?.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-divider" />
                      </div>
                    );
                  })}
                </div>
                <div className="dropdown-divider" />
                {unseenMessagesLength > 0 && (
                  <div
                    href="#"
                    className="dropdown-item dropdown-footer"
                    onClick={handleNavigateContacts}
                    style={{ cursor: "pointer" }}
                  >
                    {t("navbar.Voir tous les messages")}
                  </div>
                )}
              </div>
            </li>
          )}
          {auth && (
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell" />
                {unseenLength > 0 && (
                  <span
                    className="badge badge-warning"
                    style={{
                      fontSize: ".7rem",
                      fontWeight: "500",
                      padding: "2px 4px",
                      position: "absolute",
                    }}
                  >
                    {unseenLength}
                  </span>
                )}
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">{`${unseenLength} ${t(
                  "navbar.Notifications"
                )}`}</span>
                <div className="dropdown-divider" />
                <div className="dropdown-divider" />
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: unseenLength > 2 ? "scroll" : "hidden",
                  }}
                >
                  {unseenNotifications?.map((el) => (
                    <div key={el._id}>
                      <div className="dropdown-item">
                        {/* Message Start */}
                        <div className="media">
                          <img
                            src={`uploads/${el.sender.imageUrl}`}
                            alt={t("dashboard.avatar d'utilisateur")}
                            className="img-size-50 mr-3 img-circle"
                          />
                          <div className="media-body">
                            <h3 className="dropdown-item-title">
                              <strong>{el.sender.name}</strong>
                              <span className="float-right text-sm text-warning">
                                <i className="fas fa-star" />
                              </span>
                            </h3>
                            <p className="text-sm">
                              {el.type === 1
                                ? `${el.sender.name} ${t(
                                    "navbar.notification.confirmer son annonce"
                                  )} ${el.announcementId?.count}`
                                : el.type === 2
                                ? `${el.sender.name} ${t(
                                    "navbar.notification.confirmer votre annonce"
                                  )} ${el.announcementId?.count}`
                                : el.type === 3
                                ? `${el.sender.name} ${t(
                                    "navbar.notification.confirmer réception pour son annonce"
                                  )} ${el.announcementId?.count}`
                                : `${el.sender.name} ${t(
                                    "navbar.notification.confirmer réception pour votre annonce"
                                  )} ${el.announcementId?.count}`}
                            </p>
                            <p className="text-sm text-muted">
                              <i className="far fa-clock mr-1" />
                              {format(el.createdAt)}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Message End */}
                      <div className="dropdown-divider" />
                    </div>
                  ))}
                </div>
                <div className="dropdown-divider" />
                {unseenLength > 0 && (
                  <div
                    className="dropdown-item dropdown-footer"
                    style={{ cursor: "pointer" }}
                    onClick={handleSetSeenNotofications}
                  >
                    {t("navbar.Marquer comme vue")}
                  </div>
                )}
              </div>
            </li>
          )}
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
          <li className="nav-item">
            <div
              className={open ? "select-language-expanded" : "select-language"}
            >
              <select
                class={open ? "select-arrow-active" : "select-selected"}
                id={open ? "select-arrow" : null}
                aria-label="Default select example"
                onClick={handleOpenSelectLanguage}
                onChange={handleChangeLanguage}
              >
                <option className="lang" value="fr">
                  FR
                </option>
                <option className="lang" value="en">
                  EN
                </option>
              </select>
            </div>
          </li>
        </ul>
      </nav>
      {/* /.navbar */}
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={`uploads/${user && user.imageUrl}`}
                className="img-circle elevation-2"
                alt={t("dashboard.avatar d'utilisateur")}
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                {user && user.name}
              </a>
            </div>
          </div>
          {/* SidebarSearch Form */}
          {/* <div className="form-inline">
              <div className="input-group" data-widget="sidebar-search">
                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                  <button className="btn btn-sidebar">
                    <i className="fas fa-search fa-fw" />
                  </button>
                </div>
              </div>
            </div> */}
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library */}
              {/*<li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>
                      Mes transactions
                    </p>
                  </a>
              </li>*/}
              <li className="nav-item " onClick={handleNavigateProfil}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user" />
                  <p>{t("dashboard.mon profil")}</p>
                </a>
              </li>
              <li className="nav-item" onClick={handleNavigateCommandes}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-th" />
                  <p>{t("dashboard.mes commandes")}</p>
                </a>
              </li>
              <li className="nav-item" onClick={handleNavigateAnnonces}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-copy" />
                  <p>{t("dashboard.mes annonces")}</p>
                </a>
              </li>
              {user?.role === "admin" && (
                <>
                  <li className="nav-item" onClick={handleNavigateCreationBlog}>
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-solid fa-blog" />
                      <p>{t("dashboard.créer un blog")}</p>
                    </a>
                  </li>
                  <li className="nav-item" onClick={handleNavigateConfigSlider}>
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-solid fa-sliders"></i>
                      <p>{t("dashboard.configurer slider")}</p>
                    </a>
                  </li>
                </>
              )}
              <li className="nav-item" onClick={handleNavigateEditProfile}>
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-edit" />
                  <p>{t("dashboard.editer mon profil")}</p>
                </a>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("dashboard.mes commandes")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="fa fa-home"></i>
                      {t("dashboard.accueil")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("dashboard.mes commandes")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <Table striped bordered hover className="table">
              <thead>
                <tr>
                  <th>{t("dashboard.départ")}</th>
                  <th>{t("dashboard.arrivée")}</th>
                  <th>{t("dashboard.date")}</th>
                  <th>{t("dashboard.type de colis")}</th>
                  <th>{t("dashboard.état de la commande")}</th>
                  <th>{t("dashboard.statut du colis")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <Load />
                ) : (
                  commandes &&
                  commandes.map((commande) => (
                    <tr key={commande._id}>
                      <td>{commande.announcementId.depart}</td>
                      <td>{commande.announcementId.arrival}</td>
                      <td>{commande.announcementId.date.split("T")[0]}</td>
                      <td>{commande.announcementId.typeColis}</td>
                      {user.role === "user" ? (
                        <>
                          <td>
                            {commande.announcementId.userId === user._id &&
                            commande.commande1 === false
                              ? `${t(
                                  "dashboard.Commande confirmé de la part de l'autre utilisateur seulement!"
                                )}`
                              : commande.announcementId.userId === user._id &&
                                commande.commande2 === false
                              ? `${t(
                                  "dashboard.Commande confirmé de la part de vous seulement!"
                                )}`
                              : commande.announcementId.userId !== user._id &&
                                commande.commande1 === false
                              ? `${t(
                                  "dashboard.Commande confirmé de la part de vous seulement!"
                                )}`
                              : commande.announcementId.userId !== user._id &&
                                commande.commande2 === false
                              ? `${t(
                                  "dashboard.Commande confirmé de la part de l'autre utilisateur seulement!"
                                )}`
                              : `${t("dashboard.Commande confirmée!")}`}
                          </td>
                          <td>
                            {commande.confirmed1 === false &&
                            commande.confirmed2 === false
                              ? `${t(
                                  "dashboard.Colis pas encore réceptionné!"
                                )}`
                              : commande.announcementId.userId === user._id &&
                                commande.confirmed1 === false
                              ? `${t(
                                  "dashboard.Réception du colis confirmée de la part de l'autre utilisateur seulement!"
                                )}`
                              : commande.announcementId.userId === user._id &&
                                commande.confirmed2 === false
                              ? `${t(
                                  "dashboard.Réception du colis confirmée de la part de vous seulement!"
                                )}`
                              : commande.announcementId.userId !== user._id &&
                                commande.confirmed1 === false
                              ? `${t(
                                  "dashboard.Réception du colis confirmée de la part de vous seulement!"
                                )}`
                              : commande.announcementId.userId !== user._id &&
                                commande.confirmed2 === false
                              ? `${t(
                                  "dashboard.Réception du colis confirmée de la part de l'autre utilisateur seulement!"
                                )}`
                              : `${t(
                                  "dashboard.Colis reçu par le destinataire!"
                                )}`}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {commande.commande1 === false ||
                            commande.commande2 === false
                              ? `${t(
                                  "dashboard.Commande Confirmé de la part d'un seul utilisateur!"
                                )}`
                              : `${t("dashboard.Commande confirmée!")}`}
                          </td>
                          <td>
                            {commande.confirmed1 === false &&
                            commande.confirmed2 === false
                              ? `${t(
                                  "dashboard.Colis pas encore réceptionnée!"
                                )}`
                              : commande.confirmed1 === false ||
                                commande.confirmed2 === false
                              ? `${t(
                                  "dashboard.Réception du colis confirmée de la part d'un seul utilisateur!"
                                )}`
                              : `${t(
                                  "dashboard.Colis reçu par le destinataire!"
                                )}`}
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div>
              <ReactPaginate
                previousAriaLabel={"<<"}
                nextLabel={">>"}
                previousLabel={"<<"}
                breakLabel={"..."}
                pageCount={total}
                marginPagesDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={currentPage}
              />
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 3.2.0
        </div>
        <strong>
          Copyright © 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.
        </strong>{" "}
        All rights reserved.
      </footer>
      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
      {/* /.control-sidebar */}
    </div>
  );
}

export default MesCommandes;
