import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { setAlert } from "../redux/actions/alertActions";
import { registerProfessional } from "../redux/actions/authActions";
import "./Register.css";

function RegisterProfessional() {
  const [data, setData] = useState({
    name: "",
    phone: "",
    birth: "",
    RIB: "",
    email: "",
    password: "",
  });
  const [Photo, setPhoto] = useState(null);
  const [PatentPhoto, setPatentPhoto] = useState(null);
  const [commercialRegisterPhoto, setCommercialRegisterPhoto] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // handleChange
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  // checked
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const handleTermsConditions = (e) => {
    setAcceptedTerms(e.target.checked);
  };
  // validation coté frontend et handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data.name) {
      dispatch(setAlert("Votre nom est requis!", "danger"));
    } else if (!data.phone) {
      dispatch(setAlert("Votre numéro de téléphone est requis!", "danger"));
    } else if (!data.birth) {
      dispatch(
        setAlert(
          "Votre date de création de l'entreprise est requise!",
          "danger"
        )
      );
    } else if (!Photo) {
      dispatch(setAlert("Votre photo de l'entreprise est requise!", "danger"));
    } else if (!PatentPhoto) {
      dispatch(setAlert("Votre photo de patente est requise!", "danger"));
    } else if (!commercialRegisterPhoto) {
      dispatch(
        setAlert("Votre photo du registre de commerce est requise!", "danger")
      );
    } else if (!data.RIB) {
      dispatch(setAlert("Votre numéro de RIB est requis!", "danger"));
    } else if (!data.email) {
      dispatch(setAlert("Entrer une adresse email valide!", "danger"));
    } else if (!data.password) {
      dispatch(
        setAlert(
          "Votre mot de passe doit être au moins de 6 caractères!",
          "danger"
        )
      );
    } else if (!acceptedTerms) {
      dispatch(
        setAlert(
          "Vous devez lire et accepter les Termes et Conditions de ColisPO!",
          "danger"
        )
      );
    } else {
      dispatch(
        registerProfessional(
          data,
          acceptedTerms,
          Photo,
          PatentPhoto,
          commercialRegisterPhoto,
          navigate
        )
      );
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div>
        <section
          className="pt-5 pb-5 mt-0 align-items-center d-flex bg-dark"
          style={{
            minHeight: "100vh",
            backgroundSize: "cover",
            backgroundImage:
              "url(https://images.unsplash.com/photo-1477346611705-65d1883cee1e?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=c0d43804e2c7c93143fe8ff65398c8e9)",
          }}
        >
          <div className="container-fluid">
            <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">
              <div className="col-12 col-md-4 col-lg-3   h-50 register-container">
                <div className="card shadow">
                  <div className="card-body mx-auto">
                    <h4 className="register">
                      {t("register.professional.create")}
                    </h4>
                    <p className="text">
                      {t("register.professional.commencer")}
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          name="name"
                          className="form-control"
                          placeholder={t("register.professional.nom")}
                          type="text"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          name="phone"
                          className="form-control"
                          placeholder={t("register.professional.téléphone")}
                          type="text"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          name="birth"
                          className="form-control"
                          placeholder={t("register.professional.creation")}
                          type="date"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <label
                          for="files"
                          class="input-group-text professional1"
                        >
                          {t("register.professional.photo")}
                        </label>
                        <input
                          id="files"
                          style={{ display: "none" }}
                          type="file"
                          onChange={(e) => setPhoto(e.target.files[0])}
                        ></input>
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <label
                          for="files1"
                          class="input-group-text professional2"
                        >
                          {t("register.professional.patente")}
                        </label>
                        <input
                          id="files1"
                          style={{ display: "none" }}
                          type="file"
                          onChange={(e) => setPatentPhoto(e.target.files[0])}
                        ></input>
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <label
                          for="files2"
                          class="input-group-text professional3"
                        >
                          {t("register.professional.registre")}
                        </label>
                        <input
                          id="files2"
                          style={{ display: "none" }}
                          type="file"
                          onChange={(e) =>
                            setCommercialRegisterPhoto(e.target.files[0])
                          }
                        ></input>
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          name="RIB"
                          className="form-control"
                          placeholder={t("register.professional.rib")}
                          type="number"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-envelope" />
                          </span>
                        </div>
                        <input
                          name="email"
                          className="form-control"
                          placeholder={t("register.professional.mail")}
                          type="email"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-lock" />
                          </span>
                        </div>
                        <input
                          name="password"
                          className="form-control"
                          placeholder={t("register.professional.password")}
                          type="password"
                          onChange={handleChange}
                        />
                      </div>
                      <div class="tacbox">
                        <input
                          id="checkbox"
                          type="checkbox"
                          checked={acceptedTerms}
                          onChange={handleTermsConditions}
                        />
                        <label for="checkbox">
                          {t("register.professional.accept")}
                          <a
                            href="http://localhost:3000/Termes&Conditions"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {t("register.professional.terms")}
                          </a>{" "}
                          {t("register.professional.colispo")}
                        </label>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {t("register.professional.create")}
                        </button>
                      </div>
                      <p className="text">
                        {t("register.account")}
                        <Link to="/login"> {t("register.se connecter")}</Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default RegisterProfessional;
